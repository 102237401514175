import { rollup } from "d3";
import { sumBy, zipObject } from "lodash-es";
import type { Data, DataType } from "../../shared/types";
import { autoType } from "../../utils/autoType";

interface AggregateOptions {
  data: Data[];
  xKey: string;
  yKeys: string[];
  groupingKey?: string;
  types?: { [key: string]: DataType };
}
export function aggregate({ data, xKey, yKeys, groupingKey, types }: AggregateOptions): Data[] {
  if (yKeys.length === 0) return [];
  const parsed = autoType(data, types);
  return aggregateData(parsed, xKey, yKeys, groupingKey);
}

export function aggregateData(_data: Data[], xKey: string, _keys: string[], grouping?: string): Data[] {
  let data = _data;
  let keys = _keys;
  if (grouping) {
    data = _data.map((d) => ({ ...d, [d[grouping] as string]: d[keys[0]!] }));
    keys = Array.from(new Set(_data.map((d) => d[grouping] as string)));
  }
  const rolled = rollup(
    data,
    (vs) => zipObject(keys, [...keys.map((y) => sumBy(vs, (v) => (v[y] ?? 0) as number))]),
    (d) => d[xKey],
  );
  return Array.from(rolled.entries()).map(([key, value]) => ({ [xKey]: key, ...value }));
}

export function normalizeData(data: Data[], xValue: string): Data[] {
  const keys = Array.from(new Set(data.flatMap((d) => Object.keys(d).filter((k) => k !== xValue))));
  const normalized = data.map((d) => {
    const sum = sumBy(keys, (k) => d[k] as number);
    return zipObject(
      [xValue, ...keys],
      [d[xValue], ...keys.map((k) => ((d[k] as number) === 0 ? 0 : (d[k] as number) / sum))],
    ) as Data;
  });
  return normalized;
}
