import type { MouseMoveHandler } from "../tooltip/tooltip";
import state from "./state";

interface AreaTooltipOverlayProps {
  onMouseMove?: MouseMoveHandler;
  onMouseLeave?: () => void;
}

export const AreaTooltipOverlay = ({ onMouseMove, onMouseLeave }: AreaTooltipOverlayProps) => {
  const data = state.useTooltipData();
  const xKey = state.useXKey();
  const xScale = state.useTooltipScale();
  const innerHeight = state.useInnerHeight();
  return (
    <>
      {data.map((d, i) => {
        const x = xScale(d[xKey] as Date & string);
        return (
          <rect
            x={x}
            y={0}
            width={xScale.bandwidth()}
            height={innerHeight}
            fill="transparent"
            key={i}
            onMouseMove={(e) => onMouseMove?.(e, d)}
            onMouseLeave={onMouseLeave}
          />
        );
      })}
    </>
  );
};
