import { extent } from "d3";
import { Data } from "../shared/types";
import { Domain } from "./types";

export function getDateDomain(data: Data[], keys: string[]): Domain<Date> {
  const values = data.map((d) => keys?.map((k) => d[k])).flat() as Date[];
  const [min, max] = extent(values) as [Date, Date];
  return {
    values,
    min,
    max,
  };
}
