import { stack as d3stack } from "d3";
import { Bar } from "../bar-chart/bar";
import { getBandwidth, stackOffset } from "../bar-chart/utils";
import { BandScale, DiscreteColorScale, LinearScale } from "../scales";
import type { Data } from "../shared/types";

interface BarStackProps {
  data: Data[];
  keys: string[];
  xKey: string;
  xScale: BandScale;
  yScale: LinearScale;
  colorScale: DiscreteColorScale;
  activeXValue?: Data[keyof Data];
  activeKey?: string;
}
export const BarStack = ({ activeXValue, activeKey, data, keys, xKey, xScale, yScale, colorScale }: BarStackProps) => {
  const stack = d3stack<Data, string>();
  stack.keys(keys);
  stack.offset(stackOffset("diverging"));

  const stacks = stack(data);
  const barWidth = getBandwidth(xScale);
  const barStacks = stacks.map((stack, i) => {
    const { key } = stack;
    const bars = stack.map((d, i) => {
      // When dealing with log scales, need to check if the value is 0
      const [prev, next] = d;
      const height = isNaN(yScale(prev)) ? yScale.range()[0]! - yScale(next) : yScale(prev) - yScale(next);
      const bar = {
        key,
        index: i,
        x: xScale(d.data[xKey] as string),
        y: yScale(next),
        width: barWidth,
        height,
        color: colorScale(key),
        data: d.data,
      };
      return bar;
    });
    return { bars, index: i };
  });
  return (
    <>
      {barStacks.map((barStack) =>
        barStack.bars.map((bar) => {
          if (!bar.x) return null;
          const active =
            !activeXValue && !activeKey
              ? undefined
              : activeXValue
                ? activeXValue.toString() === bar.data[xKey]?.toString()
                : activeKey === bar.key;
          return (
            <Bar
              key={`bar-stack-${barStack.index}-${bar.index}`}
              x={bar.x}
              y={bar.y}
              width={bar.width}
              height={bar.height}
              fill={bar.color}
              active={active}
            />
          );
        }),
      )}
    </>
  );
};
