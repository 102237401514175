import { createStore, Provider } from "jotai";
import { BarSimple } from "./bar-simple";
import { BarStack } from "./bar-stack";
import { XYChartWrapper } from "../internal/components/xy-chart";
import { XYChartProps } from "../shared/types";
import { configAtom, barLineChartState } from "./state";
import { Line } from "../line-chart/line";
import { BarGroup } from "./bar-group";

export interface SeriesConfig {
  type?: "bar" | "line";
  axis?: "left" | "right";
  color?: string;
  key: string;
}

export interface BarLineProps extends XYChartProps {
  seriesConfig: SeriesConfig[];
  displayType: "grouped" | "stacked";
  yAxisScaleType?: "linear" | "log";
  yAxisRightScaleType?: "linear" | "log";
}

const BarLineChart = (props: BarLineProps) => {
  const barLeftKeys = barLineChartState.useYLeftBarKeys();
  const barRightKeys = barLineChartState.useYRightBarKeys();
  const lineLeftKeys = barLineChartState.useYLeftLineKeys();
  const lineRightKeys = barLineChartState.useYRightLineKeys();

  const xAxisScale = barLineChartState.useXScale();
  const xGroupedScale = barLineChartState.useXGroupedScale();
  const yAxisLeftScale = barLineChartState.useYLeftScale();
  const yAxisRightScale = barLineChartState.useYRightScale();
  const colorScale = barLineChartState.useColorScale();
  const data = barLineChartState.useData();
  const xTickFormat = barLineChartState.useXTickFormat();
  const yTickFormat = barLineChartState.useYTickFormat();
  const { displayType, xKey } = props;
  return (
    <XYChartWrapper
      {...props}
      yScale={yAxisLeftScale}
      xScale={xAxisScale}
      yKeys={[...barLeftKeys, ...lineLeftKeys, ...barRightKeys, ...lineRightKeys]}
      yScaleRight={yAxisRightScale || undefined}
      colorScale={colorScale}
      xTickFormatter={xTickFormat}
      yTickFormatter={yTickFormat}
    >
      <>
        {barLeftKeys.length === 1 && (
          <BarSimple
            data={data}
            xScale={xAxisScale}
            yScale={yAxisLeftScale}
            keys={barLeftKeys}
            xKey={props.xKey}
            colorScale={colorScale}
          />
        )}
        {barLeftKeys.length > 1 && displayType === "stacked" && (
          <BarStack
            data={data}
            xScale={xAxisScale}
            yScale={yAxisLeftScale}
            keys={barLeftKeys}
            xKey={props.xKey}
            colorScale={colorScale}
          />
        )}
        {barLeftKeys.length > 1 && displayType === "grouped" && (
          <BarGroup
            data={data}
            x0Scale={xAxisScale}
            x1Scale={xGroupedScale}
            yScale={yAxisLeftScale}
            keys={barLeftKeys}
            xKey={xKey}
            colorScale={colorScale}
          />
        )}
        {barRightKeys.length === 1 && (
          <BarSimple
            data={data}
            xScale={xAxisScale}
            yScale={yAxisRightScale!}
            keys={barRightKeys}
            xKey={props.xKey}
            colorScale={colorScale}
          />
        )}
        {barRightKeys.length > 1 && (
          <BarStack
            data={data}
            xScale={xAxisScale}
            yScale={yAxisRightScale!}
            keys={barRightKeys}
            xKey={xKey}
            colorScale={colorScale}
          />
        )}
        {lineLeftKeys.map((key) => (
          <Line
            key={key}
            data={data}
            xScale={xAxisScale}
            xValue={xKey}
            yScale={yAxisLeftScale}
            yValue={key}
            colorScale={colorScale}
          />
        ))}
        {lineRightKeys.map((key) => (
          <Line
            key={key}
            data={data}
            xScale={xAxisScale}
            xValue={xKey}
            yScale={yAxisRightScale!}
            yValue={key}
            colorScale={colorScale}
          />
        ))}
      </>
    </XYChartWrapper>
  );
};

const BarLineChartProvider = (props: BarLineProps) => {
  const store = createStore();
  store.set(configAtom, props);
  return (
    <Provider store={store}>
      <BarLineChart {...props} />
    </Provider>
  );
};

export default BarLineChartProvider;
