import type { PickD3Scale } from "@visx/scale";
import { atom, useAtomValue } from "jotai";
import { atomWithMachine } from "jotai-xstate";
import { selectAtom } from "jotai/utils";
import { sortBy, uniq } from "lodash-es";
import { heightAtom, widthAtom } from "../internal/atoms/dimensions";
import { categorical } from "../internal/colors";
import { createChartMachine } from "../machines";
import { createOrdinalScale } from "../scales";
import { PieChartProps } from "./pie-chart";
import type { LabelOption, PieData } from "./types";
import { getDonutThickness, prepareData } from "./util";

interface PieChartState {
  useData: () => PieData[];
  useInnerWidth: () => number;
  useInnerHeight: () => number;
  useRadius: () => number;
  useCenterX: () => number;
  useCenterY: () => number;
  useDonutThickness: () => number;
  useColorScale: () => PickD3Scale<"ordinal", string, string>;
  useLabelKey: () => string;
  useValueKey: () => string;
  useLabelOption: () => LabelOption;
  useKeys: () => string[];
}

export const configAtom = atom<PieChartProps>({} as PieChartProps);

const state: PieChartState = {
  useInnerWidth: () => useAtomValue(innerWidthAtom),
  useInnerHeight: () => useAtomValue(innerHeightAtom),
  useRadius: () => useAtomValue(radiusAtom),
  useCenterX: () => useAtomValue(centerXAtom),
  useCenterY: () => useAtomValue(centerYAtom),
  useDonutThickness: () => useAtomValue(donutThicknessAtom),
  useColorScale: () => useAtomValue(colorScaleAtom),
  useLabelKey: () => useAtomValue(labelKeyAtom),
  useValueKey: () => useAtomValue(valueKeyAtom),
  useLabelOption: () => useAtomValue(labelOptionAtom),
  useData: () => useAtomValue(displayDataAtom),
  useKeys: () => useAtomValue(allKeysAtom),
};
export default state;

const rawDataAtom = selectAtom(configAtom, (config) => config.data);
const labelKeyAtom = selectAtom(configAtom, (config) => config.labelKey);
const valueKeyAtom = selectAtom(configAtom, (config) => config.valueKey);
const holeSizeAtom = selectAtom(configAtom, (config) => config.holeSize ?? "0%");
const marginAtom = selectAtom(configAtom, (config) => config.margin ?? { top: 0, right: 0, bottom: 0, left: 0 });
const labelOptionAtom = selectAtom(configAtom, (config) => config.labelOption ?? "labelAndPercent");
const maximumCategoriesAtom = selectAtom(configAtom, (config) => config.maximumCategories ?? 8);

const innerWidthAtom = atom((get) => get(widthAtom) - get(marginAtom).left - get(marginAtom).right);
const radiusAtom = atom((get) => Math.min(get(innerWidthAtom), get(innerHeightAtom)) / 2);
const innerHeightAtom = atom((get) => get(heightAtom) - get(marginAtom).top - get(marginAtom).bottom);
const donutThicknessAtom = atom((get) => getDonutThickness(get(holeSizeAtom), get(radiusAtom)));
const centerXAtom = atom((get) => get(innerWidthAtom) / 2);
const centerYAtom = atom((get) => get(innerHeightAtom) / 2);

const dataAtom = atom((get) => {
  const rawData = get(rawDataAtom);
  const data = prepareData(rawData, get(valueKeyAtom), get(labelKeyAtom));
  const maxCategories = get(maximumCategoriesAtom);
  const valueKey = get(valueKeyAtom);
  const labelKey = get(labelKeyAtom);
  if (maxCategories && data.length > maxCategories) {
    const sorted = sortBy(data, (d) => -d[get(valueKeyAtom)]!);
    const other = sorted.slice(maxCategories).reduce(
      (acc, d) => ({
        ...acc,
        [valueKey]: (acc[valueKey] as number) + (d[valueKey] as number),
        percent: acc.percent + d.percent,
      }),
      {
        [labelKey]: "Other",
        [valueKey]: 0,
        percent: 0,
      },
    );
    return [...sorted.slice(0, maxCategories), other];
  }
  return data;
});

const displayDataAtom = atom((get) => {
  const data = get(dataAtom);
  const machine = get(chartMachineAtom);
  const { filterKeys } = machine.context.legend;
  return data.filter((d) => !filterKeys.includes(d[get(labelKeyAtom)] as string));
});

const colorScaleAtom = atom((get) => {
  const data = get(dataAtom);
  const labelKey = get(labelKeyAtom);
  const domain = uniq(data.map((d) => d[labelKey])) as string[];
  const { colorMap } = get(configAtom);
  return createOrdinalScale(
    domain,
    domain.map((k, i) => (colorMap?.[k] ?? categorical[i % categorical.length]) as string),
  );
});

const allKeysAtom = atom((get) => {
  const data = get(dataAtom);
  const labelKey = get(labelKeyAtom);
  return uniq(data.map((d) => d[labelKey])) as string[];
});

export const chartMachineAtom = atomWithMachine(
  (get) => {
    const keys = get(allKeysAtom);
    return createChartMachine(keys);
  },
  { devTools: true },
);
