import { createStore, Provider, useAtom } from "jotai";
import { localPoint } from "@visx/event";

import { BarGroup } from "./bar-group";
import { BarStack } from "./bar-stack";
import { Line } from "../line-chart/line";
import { Tooltip } from "../tooltip/tooltip";
import { BarTooltipOverlay } from "./bar-tooltip-overlay";
import barChartState, { chartMachineAtom, configAtom } from "./state";
import { BarSimple } from "./bar-simple";
import { XYChartProps } from "../shared/types";
import { XYChartWrapper } from "../internal/components/xy-chart";
import { useRunOnce } from "../internal/hooks/useRunOnce";
import { useEffect } from "react";
import { useConstant } from "../internal/hooks";

export interface BarChartProps extends XYChartProps {
  normalize?: boolean;
  yKeyLine?: string;
  yAxisLinePosition?: "left" | "right";
  yAxisScale?: "linear" | "log";
  displayType?: "stacked" | "grouped";
}

export const BarChart = (props: BarChartProps) => {
  const { displayType = "stacked" } = props;
  const yKeysLeft = barChartState.useYKeysLeft();
  const yScale = barChartState.useYScale();
  const yKeysRight = barChartState.useYKeysRight();
  const xKey = barChartState.useXKey();
  const colorScale = barChartState.useColorScale();
  const allKeys = barChartState.useAllKeys();
  const barKeys = barChartState.useBarKeys();
  const xScale = barChartState.useXScale();

  const lineData = barChartState.useLineData();
  const yScaleLine = barChartState.useYLineScale();

  const xTickFormat = barChartState.useXTickFormat();
  const yTickFormat = barChartState.useYTickFormat();
  const [state, send] = useAtom(chartMachineAtom);

  return (
    <div>
      <XYChartWrapper
        {...props}
        yScale={yScale}
        xScale={xScale}
        xTickFormatter={xTickFormat}
        yTickFormatter={yTickFormat}
        yTickFormatterRight={yTickFormat}
        yKeys={yKeysLeft}
        yKeysRight={yKeysRight ?? []}
        yScaleRight={yKeysRight?.length ? yScaleLine : undefined}
        onLegendClick={(key) => send({ type: "LEGEND.CLICK", key })}
        onLegendHover={(key) => send({ type: "LEGEND.HOVER", key })}
        onLegendLeave={() => send("LEGEND.LEAVE")}
        filteredKeys={state.context.legend.filterKeys}
        colorScale={colorScale}
      >
        <>
          {barKeys.length === 1 && <BarSimple activeKey={state.context.tooltip.activeData?.[xKey] as string} />}
          {barKeys.length > 1 && displayType === "stacked" && (
            <BarStack
              activeXValue={
                state.matches("tooltip.closed") ? null : (state.context.tooltip.activeData?.[xKey] as string | Date)
              }
              activeKey={state.context.legend.hoveredKey}
            />
          )}
          {barKeys.length > 1 && displayType === "grouped" && (
            <BarGroup
              activeXValue={
                state.matches("tooltip.closed")
                  ? undefined
                  : (state.context.tooltip.activeData?.[xKey] as string | Date)
              }
              activeKey={state.context.legend.hoveredKey}
            />
          )}
          {lineData.length && yScaleLine && (
            <Line
              data={lineData}
              xScale={xScale}
              yScale={yScaleLine}
              xValue={xKey}
              yValue={props.yKeyLine!}
              colorScale={colorScale}
              active={state.context.legend.hoveredKey === props.yKeyLine || !state.context.legend.hoveredKey}
              paddingFactor={xScale.bandwidth() / 2}
              lineGap={props.lineGap}
            />
          )}
          <BarTooltipOverlay
            onMouseMove={(event, data) => {
              if (!data) return;
              const coords = localPoint(event);
              send({ type: "TOOLTIP.OPEN", data, clientX: coords?.x ?? 0, clientY: coords?.y ?? 0 });
            }}
            onMouseLeave={() => send("TOOLTIP.CLOSE")}
          />
        </>
      </XYChartWrapper>
      {!state.matches("tooltip.closed") && (
        <Tooltip
          data={state.context.tooltip.activeData!}
          xValue={xKey}
          xFormatter={xTickFormat}
          yValues={allKeys}
          colorScale={colorScale}
          left={state.context.tooltip.clientX ?? 0}
          top={state.context.tooltip.clientY ?? 0}
          hideZeros={props.tooltip?.hideZeros ?? false}
        />
      )}
    </div>
  );
};

const BarProvider = (props: BarChartProps) => {
  const store = useConstant(() => createStore());
  useRunOnce(() => {
    store.set(configAtom, props);
  });
  useEffect(() => {
    store.set(configAtom, props);
  }, [props, store]);
  return (
    <Provider store={store}>
      <BarChart {...props} />
    </Provider>
  );
};
export default BarProvider;
