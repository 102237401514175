import { extent, group, sum } from "d3";
import { sumBy } from "lodash-es";
import { Data } from "../shared/types";
import { Domain, DomainOptions } from "./types";

/**
 * Gets the domain of data that will be displayed on a y axis
 * These domains must be numbers
 */
export function getNumericDomain(data: Data[], yValues: string[], opts?: DomainOptions): Domain<number> {
  const { nice = true } = opts || {};
  const values = data.map((d) => yValues.map((y) => d[y])).flat() as number[];
  let [min, max] = [0, 0];
  if (!opts?.stacked) {
    [min, max] = extent(values) as [number, number];
  } else {
    const groupedData = group(data, (d) => d[opts.stacked!.key]);
    const allPositiveValues = Array.from(groupedData, ([, values]) => {
      const sumValues = sumBy(values, (d) => sum(yValues.map((y) => d[y] as number).filter((v) => v >= 0)));
      return sumValues;
    });
    const allNegativeValues = Array.from(groupedData, ([, values]) => {
      const sumValues = sumBy(values, (d) => sum(yValues.map((y) => d[y] as number).filter((v) => v < 0)));
      return sumValues;
    });
    [min, max] = [Math.min(...allNegativeValues), Math.max(...allPositiveValues) as number];
  }
  if (nice) {
    [min, max] = getNiceDomain(min, max);
  }
  return {
    values,
    min,
    max,
  };
}

/**
 * A nice domain is similar to a nice scale, it changes the min / max values to be more human readable.
 * if the min is negative, we want to show the full range
 * if diff between min and max is less than 1, we want to show the full range - eg. 0.5 to 0.7
 * if the min is positive and the diff between min and max are greater than 1, we want to start at 0
 */
function getNiceDomain(min: number, max: number): [number, number] {
  if (min < 0) {
    return [min, max];
  } else {
    if (max - min < 1) {
      return [min, max];
    } else {
      return [0, max];
    }
  }
}
