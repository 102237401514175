import { atom, useSetAtom, useAtomValue } from "jotai";

const chartColorMapAtom = atom<Record<string, string>>({});
const validatedColorMapAtom = atom((get) => {
  const colorMap = get(chartColorMapAtom);
  const keys = Object.keys(colorMap);
  const values = Object.values(colorMap);
  if (keys.length === 0) {
    return {};
  }
  if (!keys.every((k) => typeof k === "string")) {
    return {};
  }
  if (!values.every((v) => typeof v === "string" || !v)) {
    return {};
  }
  return colorMap;
});
export const useSetChartColorMap = () => useSetAtom(chartColorMapAtom);
export const useChartColorMap = () => useAtomValue(validatedColorMapAtom);
