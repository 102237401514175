import { scaleBand } from "@visx/scale";

type Band = Date | string | number;
export interface BandScaleProps {
  padding?: number;
  align?: number;
  paddingInner?: number;
  paddingOuter?: number;
}
export function createBandScale<T extends Band>(
  domain: T[],
  range: [number, number],
  { padding, align, paddingInner, paddingOuter }: BandScaleProps = { padding: 0.2, align: 0.5 },
) {
  return scaleBand<T>({
    domain,
    range,
    padding,
    align,
    paddingInner,
    paddingOuter,
  });
}
