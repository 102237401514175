import clsx from "clsx";
import { LegendOrdinal as VLegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import type { PickD3Scale } from "@visx/scale";
import { isValid } from "date-fns";
import { number as formatNumber, smartTruncate } from "../internal/formatters";
import { timeFormat } from "d3";
import { autoParse } from "../utils/autoType";

interface LegendProps {
  colorScale: PickD3Scale<"ordinal", string, string>;
  domain?: string[];
  orientation?: "horizontal" | "vertical";
  onHover?: (value: string) => void;
  onLeave?: () => void;
  onClick?: (value: string) => void;
  onDoubleClick?: (value: string) => void;
  filteredKeys?: string[];
  darkMode?: boolean;
}
export const LegendOrdinal = ({
  colorScale,
  orientation = "horizontal",
  onClick,
  onHover,
  onLeave,
  filteredKeys,
}: LegendProps) => {
  const horizontalStyles = "flex flex-wrap";
  const verticalStyles = "flex flex-col gap-y-2";
  const containerStyles = clsx(orientation === "horizontal" ? horizontalStyles : verticalStyles);
  return (
    <VLegendOrdinal scale={colorScale} labelMargin="0 15px 0 0">
      {(labels) => (
        <div className={containerStyles}>
          {labels.map((label, i) => (
            <LegendItem
              key={`legend-quantile-${i}`}
              margin="0 5px"
              className={`hover:border-gray-30 cursor-pointer border border-transparent p-1 hover:rounded-lg dark:hover:border-gray-50 ${
                filteredKeys?.includes(label.datum) ? "opacity-20" : ""
              }`}
              onClick={() => onClick?.(label.datum)}
              onMouseEnter={() => onHover?.(label.datum)}
              onMouseLeave={() => onLeave?.()}
            >
              <svg width={15} height={15} style={{ marginRight: 8 }}>
                <rect fill={label.value} width={15} height={15} rx={10} />
              </svg>
              <LegendLabel align="left" margin="0 4px">
                <span className="dark:text-gray-30 text-xs" title={label.text}>
                  {getLabelDisplay(label.text)}
                </span>
              </LegendLabel>
            </LegendItem>
          ))}
        </div>
      )}
    </VLegendOrdinal>
  );
};

function getLabelDisplay(label: string) {
  const asDate = autoParse(label);
  if (isValid(asDate)) {
    return timeFormat("%b %d, %Y")(asDate as Date);
  }
  if (containsOnlyNumbers(label)) {
    return formatNumber(parseFloat(label));
  }

  return smartTruncate(label);
}

function containsOnlyNumbers(str: string) {
  return /^\d+$/.test(str);
}

export function useLegendStyles(position: "top" | "left" | "right" | "bottom") {
  const containerStyles = {
    top: "flex flex-col",
    left: "flex",
    right: "flex flex-row-reverse",
    bottom: "flex flex-col-reverse",
  };
  const orientations = {
    top: "horizontal" as const,
    left: "vertical" as const,
    right: "vertical" as const,
    bottom: "horizontal" as const,
  };

  return { containerStyle: containerStyles[position], orientation: orientations[position] };
}
