import { GridRows as VGridRows } from "@visx/grid";
import type { AnyD3Scale } from "@visx/scale";

interface GridRowsProps {
  scale: AnyD3Scale;
  width: number;
  numTicks?: number;
  offset?: number;
  left?: number;
}

export const GridRows = ({ scale, width, numTicks = 4, offset, left }: GridRowsProps) => {
  const testy = document.querySelector("body");
  const isDark = !!testy?.classList.contains("dark");
  return (
    <VGridRows
      scale={scale}
      width={width}
      numTicks={numTicks}
      offset={offset}
      left={left}
      stroke={isDark ? "rgb(51,51,51)" : "#aaa"}
    />
  );
};
