import { sortBy } from "lodash-es";
import { aggregate } from "../internal/data";
import type { Data, DataType } from "../shared/types";
import { autoType } from "../utils/autoType";

export function prepareData(
  data: Data[],
  xKey: string,
  yKeys: string[],
  groupingKey?: string,
  typeOverrides?: Record<string, DataType>,
) {
  const parsed = autoType(data, typeOverrides);
  const sorted = sortBy(parsed, xKey);

  return aggregate({ data: sorted, xKey, yKeys, groupingKey });
}
