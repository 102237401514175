import { sum } from "lodash-es";
import { aggregate } from "../internal/data";
import type { Data } from "../shared/types";
import { autoType } from "../utils/autoType";
import type { PieData, HoleSize } from "./types";

export function prepareData(rawData: Data[], valueKey: string, labelKey: string): PieData[] {
  const data = autoType(rawData);
  const aggregated = aggregate({ data, xKey: labelKey, yKeys: [valueKey] });
  const total = sum(data.map((d) => d[valueKey] as number));
  const values = aggregated.map((d) => ({
    ...d,
  }));
  return values.map((d) => ({ ...d, percent: (d[valueKey] as number) / total }));
}

export function getDonutThickness(holeSize: HoleSize, radius: number) {
  const mapping = {
    "0%": radius,
    "25%": (75 / 100) * radius,
    "50%": (50 / 100) * radius,
    "75%": (25 / 100) * radius,
  } as Record<HoleSize, number>;
  return mapping[holeSize];
}
