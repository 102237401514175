import type { AnyD3Scale } from "@visx/scale";

import { stackOffsetExpand, stackOffsetDiverging, stackOffsetNone, stackOffsetSilhouette, stackOffsetWiggle } from "d3";

export function getBandwidth(scale: AnyD3Scale) {
  if ("bandwidth" in scale) {
    return scale.bandwidth();
  }

  const range = scale.range();
  const domain = scale.domain();
  return Math.abs(range[range.length - 1] - range[0]) / domain.length;
}

export const STACK_OFFSETS = {
  expand: stackOffsetExpand,
  diverging: stackOffsetDiverging,
  none: stackOffsetNone,
  silhouette: stackOffsetSilhouette,
  wiggle: stackOffsetWiggle,
};

export const STACK_OFFSET_NAMES = Object.keys(STACK_OFFSETS);

export function stackOffset(offset?: keyof typeof STACK_OFFSETS) {
  return (offset && STACK_OFFSETS[offset]) || STACK_OFFSETS.none;
}
