import { Group } from "@visx/group";
import { Bar } from "./bar";
import state from "./state";
import { getBandwidth } from "./utils";

interface BarGroupProps {
  activeXValue?: string | Date;
  activeKey?: string;
}
export const BarGroup = ({ activeXValue, activeKey }: BarGroupProps) => {
  const data = state.useData();
  const keys = state.useBarKeys();
  const xKey = state.useXKey();
  const x0Scale = state.useXScale();
  const x1Scale = state.useXGroupedScale();
  const yScale = state.useYScale();
  const colorScale = state.useColorScale();

  const barWidth = getBandwidth(x1Scale);
  const barGroups = data.map((group, i) => ({
    index: i,
    x0: x0Scale(group[xKey] as string & Date)!,
    data: group,
    bars: keys.map((key, j) => {
      const value = group[key] as number;
      const y = Math.min(yScale(value), yScale(0));
      const height = Math.abs(yScale(value) - yScale(0));
      return {
        index: j,
        key,
        value,
        width: barWidth,
        x: x1Scale(key)!,
        y,
        color: colorScale(key),
        height,
      };
    }),
  }));
  return (
    <Group className="visx-bar-group">
      {barGroups.map((barGroup) => (
        <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
          {barGroup.bars.map((bar) => {
            const active =
              !activeXValue && !activeKey
                ? undefined
                : activeXValue
                  ? activeXValue.toString() === barGroup.data[xKey]?.toString()
                  : activeKey === bar.key;
            return (
              <Bar
                key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                fill={bar.color}
                active={active}
              />
            );
          })}
        </Group>
      ))}
    </Group>
  );
};
