import { atom, useAtomValue } from "jotai";
import type { TickFormatter } from "@visx/axis";
import type { Data } from "../shared/types";
import { selectAtom } from "jotai/utils";
import { prepareData } from "./util";
import { bucket, fillMissingDates, isTimeSeries } from "../internal/data";
import { identity, keyBy, omit } from "lodash-es";
import { getXTickFormat, getYTickFormat } from "../utils/tickFormat";
import { categorical } from "../internal/colors";
import { atomWithMachine } from "jotai-xstate";
import { createChartMachine } from "../machines";
import { LineChartProps } from "./line-chart";
import {
  BandScale,
  createScale,
  createOrdinalScale,
  DiscreteColorScale,
  type LinearScale,
  type LogScale,
  type TimeScale,
} from "../scales";
import { getDateDomain, getNumericDomain, getStringDomain } from "../domain";
import { heightAtom, widthAtom } from "../internal/atoms/dimensions";

interface LineChartState {
  useData: () => Data[];
  useDataRight: () => Data[];
  useInnerHeight: () => number;
  useInnerWidth: () => number;
  useXKey: () => string;
  useXScale: () => TimeScale | LinearScale | BandScale;
  useXTickFormat: () => TickFormatter<Date>;
  useYScale: () => LinearScale | LogScale;
  useYScaleRight: () => LinearScale | undefined;
  useYTickFormat: () => TickFormatter<number>;
  useKeys: () => string[];
  useKeysRight: () => string[];
  useAllKeys: () => string[];
  useColorScale: () => DiscreteColorScale;
  useTooltipScale: () => BandScale;
  useTooltipData: () => Data[];
}

export const configAtom = atom<LineChartProps>({} as LineChartProps); // This will be initialized by Provider

const state: LineChartState = {
  useData: () => useAtomValue(displayDataAtom),
  useDataRight: () => useAtomValue(displayDataRightAtom),
  useInnerWidth: () => useAtomValue(innerWidthAtom),
  useInnerHeight: () => useAtomValue(innerHeightAtom),
  useXKey: () => useAtomValue(xKeyAtom),
  useXScale: () => useAtomValue(xScaleAtom),
  useXTickFormat: () => useAtomValue(xTickFormatterAtom),
  useYScale: () => useAtomValue(yScaleAtom),
  useYScaleRight: () => useAtomValue(yScaleRightAtom),
  useYTickFormat: () => useAtomValue(yTickFormatterAtom),
  useKeys: () => useAtomValue(lineKeysAtom),
  useKeysRight: () => useAtomValue(lineKeysRightAtom),
  useAllKeys: () => useAtomValue(allKeysAtom),
  useColorScale: () => useAtomValue(colorScaleAtom),
  useTooltipScale: () => useAtomValue(tooltipScaleAtom),
  useTooltipData: () => useAtomValue(tooltipDataAtom),
};
export default state;

const DEFAULT_MARGIN = { top: 50, right: 60, bottom: 50, left: 60 };

const rawDataAtom = selectAtom(configAtom, (config) => config.data);
const marginAtom = selectAtom(configAtom, (config) => config.margin ?? DEFAULT_MARGIN);
const xKeyAtom = selectAtom(configAtom, (config) => config.xKey);
const yKeysAtom = selectAtom(configAtom, (config) => config.yKeys);
const yKeysRightAtom = selectAtom(configAtom, (config) => config.yKeysRight);
const groupingKeyAtom = selectAtom(configAtom, (config) => config.groupingKey);
const yScaleTypeAtom = selectAtom(configAtom, (config) => config.yAxisScale ?? "auto");
const xScaleTypeAtom = selectAtom(configAtom, (config) => config.xAxisScale ?? "auto");
const maximumCategoriesAtom = selectAtom(configAtom, (config) => config.maximumCategories ?? 8);
const typeOverridesAtom = selectAtom(configAtom, (config) => config.typeOverrides);
const lineGapAtom = selectAtom(configAtom, (config) => config.lineGap ?? "none");

// *************************
// * DIMENSIONS
// *************************
const innerWidthAtom = atom((get) => {
  const width = get(widthAtom);
  const margin = get(marginAtom);
  return width - margin.left - margin.right;
});

const innerHeightAtom = atom((get) => {
  const height = get(heightAtom);
  const margin = get(marginAtom);
  return height - margin.top - margin.bottom;
});

// *************************
// * DATA
// *************************

const dataAtom = atom((get) => {
  const rawData = get(rawDataAtom);
  const xKey = get(xKeyAtom);
  const yKeys = get(yKeysAtom);
  const groupingValue = get(groupingKeyAtom);
  const typeOverrides = get(typeOverridesAtom);
  const data = prepareData(rawData, xKey, yKeys, groupingValue, typeOverrides);
  if (isTimeSeries(data, xKey) && get(lineGapAtom) !== "none") {
    return fillMissingDates(data, xKey);
  }
  return data;
});

const dataRightAtom = atom((get) => {
  const rawData = get(rawDataAtom);
  const xKey = get(xKeyAtom);
  const yKeys = get(yKeysRightAtom);
  if (!yKeys) return [];
  const typeOverrides = get(typeOverridesAtom);
  const data = prepareData(rawData, xKey, yKeys, undefined, typeOverrides);
  if (isTimeSeries(data, xKey) && get(lineGapAtom) !== "none") {
    return fillMissingDates(data, xKey);
  }
  return data;
});

const limitedBucketedDataAtom = atom((get) => {
  const data = get(dataAtom);
  const xKey = get(xKeyAtom);
  const maxCategories = get(maximumCategoriesAtom);
  if (!maxCategories) return data;
  return bucket(data, xKey, maxCategories);
});

const limitedBucketedDataRightAtom = atom((get) => {
  const data = get(dataRightAtom);
  const xKey = get(xKeyAtom);
  const maxCategories = get(maximumCategoriesAtom);
  if (!maxCategories) return data;
  return bucket(data, xKey, maxCategories);
});

const filteredDataAtom = atom((get) => {
  const data = get(limitedBucketedDataAtom);
  const { filterKeys } = get(chartMachineAtom).context.legend;
  if (filterKeys.length === 0) return data;
  return data.map((d) => omit(d, filterKeys)) as Data[];
});

const filteredDataRightAtom = atom((get) => {
  const data = get(limitedBucketedDataRightAtom);
  const { filterKeys } = get(chartMachineAtom).context.legend;
  if (filterKeys.length === 0) return data;
  return data.map((d) => omit(d, filterKeys));
});

const displayDataAtom = atom((get) => {
  const data = get(filteredDataAtom);
  return data;
});

const displayDataRightAtom = atom((get) => {
  const data = get(filteredDataRightAtom);
  return data;
});

// *************************
// * KEYS
// *************************
const lineKeysAtom = atom((get) => {
  const data = get(limitedBucketedDataAtom);
  const { filterKeys } = get(chartMachineAtom).context.legend;
  if (data.length === 0) return [];
  const xKey = get(xKeyAtom);
  return Object.keys(data[0]!).filter((key) => key !== xKey && !filterKeys.includes(key));
});

const lineKeysRightAtom = atom((get) => {
  const data = get(limitedBucketedDataRightAtom);
  if (data.length === 0) return [];
  const xKey = get(xKeyAtom);
  return Object.keys(data[0]!).filter((key) => key !== xKey);
});

const displayKeysAtom = atom((get) => {
  const data = get(displayDataAtom);
  if (data.length === 0) return [];
  const xKey = get(xKeyAtom);
  return Object.keys(data[0]!).filter((k) => k !== xKey);
});

const allKeysAtom = atom((get) => {
  const data = get(limitedBucketedDataAtom);
  const xKey = get(xKeyAtom);
  if (data.length === 0) return [];
  const keys = Object.keys(data[0]!).filter((k) => k !== xKey);
  const keysRight = get(yKeysRightAtom);
  if (keysRight) return [...keys, ...keysRight];
  return keys;
});

// *************************
// * DOMAINS
// *************************

const yDomainAtom = atom((get) => {
  const data = get(displayDataAtom);
  const displayKeys = get(displayKeysAtom);
  return getNumericDomain(data, displayKeys);
});

const yDomainRightAtom = atom((get) => {
  const data = get(displayDataRightAtom);
  if (!data.length) return undefined;
  const displayKeys = get(yKeysRightAtom);
  return getNumericDomain(data, displayKeys!);
});

const xDomainAtom = atom((get) => {
  const data = get(displayDataAtom);
  const xKey = get(xKeyAtom);
  if (isTimeSeries(data, xKey)) {
    return getDateDomain(data, [xKey]);
  }
  if (typeof data[0]?.[xKey] === "string") {
    return getStringDomain(data, [xKey]);
  }
  return getNumericDomain(data, [xKey], { nice: false });
});

// *************************
// * FORMATTERS
// *************************
export const xTickFormatterAtom = atom((get) => {
  const xKey = get(xKeyAtom);
  const data = get(displayDataAtom);
  if (!data.length) return identity;
  return getXTickFormat(data, xKey) as TickFormatter<Date>;
});

export const yTickFormatterAtom = atom((get) => {
  const { min, max } = get(yDomainAtom);
  return getYTickFormat([min, max]);
});

// *************************
// * SCALES
// *************************
const xScaleAtom = atom((get) => {
  const { values } = get(xDomainAtom);
  const width = get(innerWidthAtom);
  const scaleType = get(xScaleTypeAtom);
  return createScale(values, [0, width], scaleType) as LinearScale | BandScale | TimeScale;
});

const tooltipScaleAtom = atom((get) => {
  const data = get(displayDataAtom);
  const xKey = get(xKeyAtom);
  const domain = data.map((d) => d[xKey] as Date);
  const width = get(innerWidthAtom);
  return createScale(domain, [0, width], "band", { padding: 0 }) as BandScale;
});

export const yScaleAtom = atom((get) => {
  const { min, max } = get(yDomainAtom);
  const height = get(innerHeightAtom);
  const scaleType = get(yScaleTypeAtom);
  return createScale([min, max], [height, 0], scaleType) as LinearScale | LogScale;
});

export const yScaleRightAtom = atom((get) => {
  const domain = get(yDomainRightAtom);
  const height = get(innerHeightAtom);
  if (!domain) return;
  return createScale([domain.min, domain.max], [height, 0], "linear") as LinearScale;
});

const colorScaleAtom = atom((get) => {
  const keys = get(allKeysAtom);
  const { colorMap } = get(configAtom);
  return createOrdinalScale(
    keys,
    keys.map((k, i) => (colorMap?.[k] ?? categorical[i % categorical.length]) as string),
  );
});

export const tooltipDataAtom = atom((get) => {
  const data = get(displayDataAtom);
  const dataRight = get(displayDataRightAtom);
  const xKey = get(xKeyAtom);
  const keyedRightData = keyBy(dataRight, xKey);
  return data.map((d) => ({ ...d, ...keyedRightData[d[xKey] as string] }));
});

export const chartMachineAtom = atomWithMachine((get) => {
  const keys = get(allKeysAtom);
  return createChartMachine(keys);
});
