/**
 * Bucketing data allows the "TOP" keys get displayed, and everything after a cutoff
 * will just be considered "Other"
 */

import { keyBy, omitBy, sortBy, sumBy } from "lodash-es";
import type { Data } from "../../shared/types";

export function bucket(data: Data[], xValue: string, numBuckets = 8): Data[] {
  const topKeys = getTopKeys(data, xValue, numBuckets);
  const other = getOtherData(data, xValue, topKeys);

  const top = data.map((d) => omitBy(d, (_, k) => k !== xValue && !topKeys.includes(k)));
  // Filters out other if not applicable
  if (other.every((d) => d.Other === 0)) return top;

  const otherKeyed = keyBy(other, xValue);
  return top.map((d) => ({ ...d, ...otherKeyed[d[xValue] as string] }));
}

export function getTopKeys(data: Data[], xValue: string, limit: number): string[] {
  const keys = Array.from(new Set(data.flatMap((d) => Object.keys(d).filter((k) => k !== xValue))));
  const summed = data.reduce((acc, d) => {
    return keys.reduce((keyAcc, k) => ({ ...keyAcc, [k]: +(keyAcc[k] ?? 0) + +(d[k] ?? 0) }), acc);
  }, {});
  const top = sortBy(Object.keys(summed), (k) => -Math.abs((summed[k] as number) ?? 0));
  return top.slice(0, limit);
}

export function getOtherData(data: Data[], xValue: string, topKeys: string[]): Data[] {
  const withoutTopData = data.map((d) => omitBy(d, (_, k) => k === xValue || topKeys.includes(k)));
  const keys = Array.from(new Set(withoutTopData.flatMap((d) => Object.keys(d).filter((k) => k !== xValue))));
  return data.map((d) => ({ [xValue]: d[xValue], Other: sumBy(keys, (k) => d[k] as number) }));
}
