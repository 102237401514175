import { differenceInDays, differenceInHours, differenceInMonths } from "date-fns";
import { timeFormat } from "d3";
import { isDailyData, isHourlyData, isMinuteData, isMonthlyData, isWeeklyData } from "../data/time";
import { Data } from "../../shared/types";

export const formatDate = (start: Date, end: Date) => {
  if (differenceInHours(end, start) < 1) return timeFormat("%H:%M");
  if (differenceInDays(end, start) < 1) return timeFormat("%H");
  if (differenceInMonths(end, start) < 1) return timeFormat("%b %d, %Y");
  return timeFormat("%b %Y");
};

export const formatDateFromData = (data: Data[], key: string) => {
  const isHourly = isHourlyData(data, key);
  const isDaily = isDailyData(data, key);
  const isWeekly = isWeeklyData(data, key);
  const isMonthly = isMonthlyData(data, key);
  const isMinutes = isMinuteData(data, key);

  if ((isHourly || isMinutes) && !isDaily) return timeFormat("%b %d %H:%M");
  if (isDaily) return timeFormat("%b %d, %Y");
  if (isWeekly) return timeFormat("%b %d, %Y");
  if (isMonthly) return timeFormat("%b %Y");
  return timeFormat("%Y");
};
