import { Group } from "@visx/group";
import { Bar } from "../bar-chart/bar";
import { getBandwidth } from "../bar-chart/utils";
import { BandScale, DiscreteColorScale, LinearScale } from "../scales";
import { Data } from "../shared/types";

interface BarGroupProps {
  data: Data[];
  keys: string[];
  xKey: string;
  x0Scale: BandScale;
  x1Scale: BandScale;
  yScale: LinearScale;
  colorScale: DiscreteColorScale;
}
export const BarGroup = ({ colorScale, data, x1Scale, x0Scale, xKey, keys, yScale }: BarGroupProps) => {
  const barWidth = getBandwidth(x1Scale);
  const barGroups = data.map((group, i) => ({
    index: i,
    x0: x0Scale(group[xKey] as string)!,
    data: group,
    bars: keys.map((key, j) => {
      const value = group[key] as number;
      const y = Math.min(yScale(value), yScale(0));
      const height = Math.abs(yScale(value) - yScale(0));
      return {
        index: j,
        key,
        value,
        width: barWidth,
        x: x1Scale(key)!,
        y,
        color: colorScale(key),
        height,
      };
    }),
  }));
  return (
    <Group className="visx-bar-group">
      {barGroups.map((barGroup) => (
        <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
          {barGroup.bars.map((bar) => {
            return (
              <Bar
                key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                fill={bar.color}
                active={true}
              />
            );
          })}
        </Group>
      ))}
    </Group>
  );
};
