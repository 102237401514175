import type { visualization } from "@fscrypto/domain";

export function createTypeOverrides(chart: visualization.XYVisualization): Record<string, visualization.DataType> {
  const typeOverrides: Record<string, visualization.DataType> = {};
  if (chart.xAxisType && chart.xAxisValue) {
    typeOverrides[chart.xAxisValue] = chart.xAxisType;
  }
  if (chart.yAxisType && chart.yAxisValues.length) {
    chart.yAxisValues.forEach((yAxisValue) => {
      typeOverrides[yAxisValue] = chart.yAxisType;
    });
  }
  return typeOverrides;
}
