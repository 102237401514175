import type { Data, DisplayMode } from "@fscrypto/viz";
import { BigNumber, ParentSize } from "@fscrypto/viz";
import type { visualization } from "@fscrypto/domain";
import { EmptyChartPrompt } from "../chart-components/empty-chart-prompt";
import type { ChartType } from "../chart-components/new";

interface BigNumberProps {
  chart: visualization.BigNumber;
  data: Data[];
  displayMode?: DisplayMode;
  context?: "editor" | "dashboard";
}

export const BigNumberVisualization = ({ data, chart, context }: BigNumberProps) => {
  if (data.length === 0) return null;
  const row = data[(chart.rowNumber ?? 1) - 1];
  if (row === undefined) {
    return context === "editor" ? <EmptyChartPrompt chartType={"big_number" as ChartType} /> : null;
  }
  const key = chart.valueKey;
  const value = Number(row[key!]);
  if (isNaN(value)) return <EmptyChartPrompt chartType={"big_number" as ChartType} />;
  return (
    <div className="h-full w-full">
      <ParentSize>
        {({ width, height }) => (
          <BigNumber
            width={width}
            height={height}
            caption={chart.caption}
            data={value}
            prefix={chart.prefix}
            suffix={chart.suffix}
            decimals={chart.decimals}
            autoFormat={chart.autoFormat}
            d3Format={chart.d3Format}
          />
        )}
      </ParentSize>
    </div>
  );
};
