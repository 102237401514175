import clsx from "clsx";
import { LegendLinear, LegendItem, LegendLabel } from "@visx/legend";
import { number as formatNumber } from "../internal/formatters";
import type { ContinuousColorScale } from "../colors";

interface LegendProps {
  colorScale: ContinuousColorScale;
  orientation?: "horizontal" | "vertical";
  onHover?: (value: string) => void;
  onLeave?: () => void;
  onClick?: (value: string) => void;
  onDoubleClick?: (value: string) => void;
  filteredKeys?: string[];
  darkMode?: boolean;
}
export const LegendContinuous = ({ colorScale, orientation = "horizontal" }: LegendProps) => {
  const horizontalStyles = "flex flex-wrap";
  const verticalStyles = "flex flex-col gap-y-2";
  const containerStyles = clsx(orientation === "horizontal" ? horizontalStyles : verticalStyles);
  return (
    <LegendLinear scale={colorScale} labelMargin="0 15px 0 0">
      {(labels) => (
        <div className={containerStyles}>
          {labels.map((label, i) => (
            <LegendItem
              key={`legend-quantile-${i}`}
              margin="0 5px"
              className="hover:rounded-lg p-1 border border-transparent hover:border-gray-30 cursor-pointer"
            >
              <svg width={15} height={15} style={{ marginRight: 8 }}>
                <rect fill={label.value} width={15} height={15} rx={10} />
              </svg>
              <LegendLabel align="left" margin="0 4px">
                <span className="text-xs max-w-[75px] truncate" title={label.text}>
                  {getLabelDisplay(label.text)}
                </span>
              </LegendLabel>
            </LegendItem>
          ))}
        </div>
      )}
    </LegendLinear>
  );
};

function getLabelDisplay(label: string) {
  if (!isNaN(parseFloat(label)) && !label.startsWith("0x")) {
    return formatNumber(parseFloat(label));
  }
  return label;
}
