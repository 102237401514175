import type { Data, DisplayMode, Margin } from "@fscrypto/viz";
import { BarChartVisualization } from "./bar-chart";
import { AreaChartVisualization } from "./area-chart";
import { LineChartVisualization } from "./line-chart";
import { ScatterChartVisualization } from "./scatter-chart";
import { BigNumberVisualization } from "./big-number";
import { Donut } from "./donut";
import { BarLineChartVisualization } from "./bar-line-legacy-chart";

import ResetErrorBoundary from "~/features/errors/ResettableErrorBoundary";
import { Collapsible } from "@fscrypto/ui";
import { useSetChartColorMap } from "../settings/state";
import { EmptyChartPrompt } from "../chart-components/empty-chart-prompt";
import type { ChartType } from "../chart-components/new";
import { memo, useEffect } from "react";
import { VisLoading } from "~/features/dashboard/dashboard-panel-visualizations/vizLoading";
import { RealtimeVisualization } from "@fscrypto/domain/liveblocks";
import { EmptyQueryRun } from "~/features/query/ui/query-run/query-run-panel";
import { PlusIcon } from "lucide-react";
import { useVisualization } from "../../state/visualization";

type VisualizationProps = {
  data: Data[];
  displayMode?: DisplayMode;
  margin?: Margin;
  visualization: RealtimeVisualization;
  context: "editor" | "dashboard";
};

const isEmptyChart = (vis: RealtimeVisualization) => {
  switch (vis.chart.type) {
    case "area":
    case "bar":
    case "line":
      return vis.chart.xAxisValue === undefined || vis.chart.yAxisValues.length === 0;
    case "donut":
      return vis.chart.valueKey === undefined || vis.chart.labelKey === undefined;
    case "scatter":
      return vis.chart.xAxisValue === undefined || vis.chart.yAxisValue === undefined;
    default:
      return false;
  }
};

export const VisualizationDisplay = ({ visualization, data, displayMode, margin, context }: VisualizationProps) => {
  const { chart, legend } = visualization;
  const setChartColorMap = useSetChartColorMap();

  useEffect(() => {
    if (isEmptyChart(visualization)) {
      setChartColorMap({});
    }
  }, [visualization, setChartColorMap]);
  if (!data) return <VisLoading />;
  if (data.length === 0) return <EmptyQueryRun isDashboard={context === "dashboard"} />;

  if (isEmptyChart(visualization)) {
    return context === "editor" ? <EmptyChartPrompt chartType={chart.type as ChartType} /> : null;
  }

  switch (chart.type) {
    case "bar": {
      return (
        <BarChartVisualization chart={chart} data={data} legend={legend} displayMode={displayMode} margin={margin} />
      );
    }
    case "big_number": {
      return <BigNumberVisualization data={data} chart={chart} />;
    }
    case "line": {
      return (
        <LineChartVisualization chart={chart} data={data} legend={legend} displayMode={displayMode} margin={margin} />
      );
    }
    case "area": {
      return (
        <AreaChartVisualization chart={chart} legend={legend!} data={data} displayMode={displayMode} margin={margin} />
      );
    }
    case "scatter": {
      return (
        <ScatterChartVisualization
          chart={chart}
          data={data}
          displayMode={displayMode}
          legend={legend}
          margin={margin}
        />
      );
    }
    case "donut": {
      return <Donut chart={chart} data={data} legend={legend} displayMode={displayMode} margin={margin} />;
    }
    case "bar_line": {
      return (
        <BarLineChartVisualization
          chart={chart}
          data={data}
          legend={legend}
          displayMode={displayMode}
          margin={margin}
        />
      );
    }
  }
};

type VisProps = Omit<VisualizationProps, "visualization"> & { id: string; context?: "editor" | "dashboard" };
export const VisualizationComponent = ({ id, ...props }: VisProps) => {
  const { visualization, isReady } = useVisualization(id);
  if (!isReady) return null;
  return (
    <ResetErrorBoundary fallbackRender={({ error }) => <VisualizationError error={error} />} resetKeys={[id]}>
      <VisualizationDisplay visualization={visualization} {...props} />
    </ResetErrorBoundary>
  );
};

export const Visualization = memo(VisualizationComponent);

export const VisualizationError = ({ error }: { error: Error | null }) => {
  return (
    <div className="dark:text-gray-30 flex h-full w-full flex-col items-center justify-center gap-y-4 p-4">
      <div className="dark text-sm">
        Sorry, we are unable to render this visualization. Please check your query and data to ensure that everything
        looks correct.
      </div>
      <div className="text-sm">
        If this problem persist, please create a ticket in Intercom using the purple launcher in the bottom left corner
        of the app!
      </div>
      {error?.stack && (
        <div>
          <Collapsible.Root>
            <Collapsible.Trigger>
              <div className="dark:text-gray-30 text-gray-70 flex items-center border px-8">
                <PlusIcon className="h-8 w-8" /> <p>Click to See Error Details</p>
              </div>
            </Collapsible.Trigger>
            <Collapsible.Content>
              <p className="text-gray-60 dark:text-gray-30 text-left text-2xl font-bold">{error?.name}</p>
              <p className="text-gray-60 dark:text-gray-30 text-left text-2xl font-bold">{error?.message}</p>
            </Collapsible.Content>
          </Collapsible.Root>
        </div>
      )}
    </div>
  );
};

export default Visualization;
