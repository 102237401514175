import { format } from "d3";
import { round } from "lodash-es";

export function formatNumber(num: number): string {
  if (num < 0.01 && num > -0.01) {
    return format(".6~f")(num);
  }
  if (num < 2 && num > 0) {
    return format(".6~f")(num);
  }
  if (num < 1000 && num > -1000) {
    return format(".3~f")(num);
  }
  if (num < 1_000_000 && num > -1_000_000) {
    return format(".3~s")(num);
  }
  if (num < 1_000_000_000 && num > -1_000_000_000) {
    return format(".3~f")(round(num / 1_000_000, 3)) + "M";
  }
  return format(".3~f")(round(num / 1_000_000_000, 3)) + "B";
}

export default formatNumber;
