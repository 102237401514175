import type { visualization } from "@fscrypto/domain";
import { LineChart, ParentSize } from "@fscrypto/viz";
import { getMargin } from "./shared";
import type { ChartProps } from "./types";
import { useSetChartColorMap } from "../settings/state";
import { createTypeOverrides } from "../settings/util";

interface LineChartProps extends ChartProps<visualization.LineChart> {}

export const LineChartVisualization = ({ chart, data, legend, displayMode, margin }: LineChartProps) => {
  const {
    xAxisValue,
    yAxisValues,
    groupByValue,
    yAxisValuesRight,
    xAxisLabel,
    yAxisLabel,
    yAxisLabelRight,
    yAxisScale,
  } = chart;
  const typeOverrides = createTypeOverrides(chart);
  const setChartColorMap = useSetChartColorMap();
  return (
    <div className="h-full w-full flex-col gap-x-2">
      <ParentSize>
        {({ width, height }) => (
          <LineChart
            data={data}
            xKey={xAxisValue!}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            yAxisScale={yAxisScale as "linear" | "log" | undefined}
            yAxisLabelRight={yAxisLabelRight}
            yKeys={yAxisValues}
            yKeysRight={yAxisValuesRight}
            groupingKey={groupByValue}
            showLegend={legend.show}
            legendPosition="top"
            width={width}
            height={height}
            margin={margin ?? getMargin(width)}
            displayMode={displayMode}
            maximumCategories={chart.maximumCategories}
            typeOverrides={typeOverrides}
            lineGap={chart.lineGap}
            xAxisSortDirection={chart.xAxisSortDirection}
            onChartOutputChange={(c) => setChartColorMap(c.colorMap)}
            colorMap={legend.colorMap}
            tooltip={chart.tooltip}
          />
        )}
      </ParentSize>
    </div>
  );
};
