import { Atom } from "jotai";
import { atomWithMachine } from "jotai-xstate";
import { assign, createMachine } from "xstate";
import type { Data } from "../shared/types";

export interface ChartContext {
  tooltip: {
    activeData?: Data;
    clientX?: number;
    clientY?: number;
    yValues?: string[];
  };
  legend: {
    filterKeys: string[];
    hoveredKey?: string;
    keys: string[];
  };
}

type Event =
  | { type: "TOOLTIP.OPEN"; data: Data; clientX: number; clientY: number; yValues?: string[] }
  | { type: "TOOLTIP.CLOSE" }
  | { type: "LEGEND.HOVER"; key: string }
  | { type: "LEGEND.LEAVE" }
  | { type: "LEGEND.SETKEYS"; keys: string[] }
  | { type: "LEGEND.CLICK"; key: string }
  | { type: "LEGEND.DOUBLECLICK"; key: string };

export const createChartMachineAtom = (keysAtom: Atom<string[]>) => {
  return atomWithMachine((get) => {
    const keys = get(keysAtom);
    return createChartMachine(keys);
  });
};

export const createChartMachine = (keys: string[]) =>
  /** @xstate-layout N4IgpgJg5mDOIC5QGMAWBDATgFwHTYHsCAbbASwAddliDZIBiAFQHkWAZJgSQAVcWeAUQByAbQAMAXUSgKdMuQIA7GSAAeiAGwBmAOy4AjOIMBWTQA5z47eIAsBgDQgAnonsnctgJw+vBgzoG5rpeAL6hTmhYeIQk5FQQYMTozpAAwrT0EMxsnLz8QmJSqnKwCmTKqhoIAEzaXrh2Fl62NeatBl7m2k6utboGuLrePpoG2rZWuprhkRg4+ESklLiJyakQGXSMarDY6NhguOgAZoeYABQ24gCUDFELscsJSSnpmZAS0kggpeWVP2qdQaTXMLTaHS6PRcbgM+nECOMNVMXmMJgMsxADxiS3iuAIFDASkYrA43D4aXYLAAyoIviV5IoVICtHpDGiLFYbPZeohTINEQiLAF6iZbDMIlj5nhiGAYEoILhUAQAG5gTC4MhKdDIchqhjsQQAcREABFcAAJFgANUEACV6T8-kyqogBuJcJYatM-IjtGZeQhUeZcCY6rYTGYatY9LZMdjcLL5YrlWqNWs3hAuNrdWR9YaTcJzVbbQ7ik7GRVmaAgeYPJovCZtHVumNbO5A7pgkMTAjtJo2l4Bl149LE3KiSnVerVq8NtmdXqwAxdvtDsczuqrgi7gmk5OldP03PIAvc2rHbJKwCa4g2vXG83tK2DO3TIGvNptI1EWHtOMvG9ONJT3CcFUPNNjnPZcCzNXBDQAQVtS9fmvat1EQcxxlwWMun8PQh3EXRA00TR4URCx0S8MZulHaJx2TXATjIUgZy1Rc8xg404MpLg0gAaRQ50q1dWoglwaMbDDAxvXEYI-EDGS-AkqMfG5Gx2johZ93A5jWI1djoINbii1wU0WAAVQAIUNXiBKEtDRORENJPEaTZPkxwYQQcZu10cQWl0GpbC-b0Wi0mUwMVPTziYljzkYWDTLswTyyvMoXRZBAm00XAyJMAYTC6EKai8RSbH0Z9SuCsVxSCiKGIPGKZ2azBEpM81zOs2z2D41LvnS-50OqOqJJqGoTCsVoBgjcxFORD1AKHdF3H8uSGp06L4pa7a2uyJLOssmzBBS0QDAG1CMpErKIw8Fom35bpdH7TRFP8WxPGmUj2xCIcag2qK4v0oGEuyByrpvDDahaTwLCCvwGzFCYTHmiMVJ0Ww3MmiZxXCSUlAIRJ4B+bEGQh4bEAAWlaQNKbqXBfC6Bt-UAzG6wap54jJoanNsEjv17BFXyCOomw53EVhobYIG5zLbwQewPXaPQJumHKJsDeoalDQU1tMGwaglOZ6M5lYMw2LYsll675aCwNDYaXRBbhCx-yCcZxbiFYCSJSBrch6pzG9Ro6y6CadBad9vL0QYZMFlpggmNEAeTf2KYQV7vO8bXEXGYZxGC6xgON7TAdTdU09EsVAwKhpnYL6ijCMcwU4PcuDJzJdK5uzQPubLD7H7dtNCKj85J1hFbEK3vWhMVvwPb2d1lPTvOO7+WjBqEinc9J23ML8UGwxECx02iCZw4tV16hrp9ENswgnMMY4WI7zpjvwX3KsAd-pP+iz9atfao1dvKb1ykOUibRhifjknPP+pdGKtU1KvK+FZyaiU3uVGSEl+7-lRGKOBJdIqIN2iDdUfs0E8yypNfQYxmyfmqnVLeoC2aen7AFd+Q9tB41CEAA */
  createMachine(
    {
      id: "chart",
      states: {
        tooltip: {
          initial: "closed",
          states: {
            closed: {
              entry: ["clearTooltipData"],
              on: {
                "TOOLTIP.OPEN": {
                  target: "opened",
                },
              },
            },
            delayedClosed: {
              on: {
                "TOOLTIP.OPEN": {
                  target: "opened",
                },
              },
              after: {
                300: {
                  target: "closed",
                },
              },
            },
            opened: {
              entry: ["setTooltipData"],
              on: {
                "TOOLTIP.CLOSE": {
                  target: "delayedClosed",
                },
                "TOOLTIP.OPEN": {
                  target: "opened",
                  actions: ["setTooltipData"],
                },
              },
            },
          },
        },
        legend: {
          states: {
            hover: {
              initial: "inactive",
              states: {
                inactive: {
                  entry: ["removeHoveredKey"],
                  on: {
                    "LEGEND.HOVER": {
                      target: "active",
                      actions: ["setHoveredKey"],
                      cond: "notFiltered",
                    },
                  },
                },
                delayedInactive: {
                  on: {
                    "LEGEND.HOVER": {
                      target: "active",
                    },
                  },
                  after: {
                    300: "inactive",
                  },
                },
                active: {
                  entry: ["setHoveredKey"],
                  on: {
                    "LEGEND.LEAVE": {
                      target: "delayedInactive",
                    },
                  },
                },
              },
            },

            filter: {
              states: {
                inactive: {
                  on: {
                    "LEGEND.CLICK": {
                      target: "filtered",
                      actions: ["addOrRemoveFilterKey"],
                    },

                    "LEGEND.DOUBLECLICK": {
                      target: "filtered",
                      actions: "setFocusKey",
                    },
                  },
                },
                filtered: {
                  always: [{ target: "inactive", cond: "emptyFilters" }],

                  on: {
                    "LEGEND.CLICK": {
                      target: "filtered",
                      internal: true,
                      actions: "addOrRemoveFilterKey",
                    },

                    "LEGEND.DOUBLECLICK": [
                      {
                        target: "filtered",
                        internal: true,
                        cond: "notInFocus",
                        actions: "setFocusKey",
                      },
                      {
                        target: "filtered",
                        internal: true,
                        cond: "isInFocus",
                        actions: "setAllButKey",
                      },
                    ],
                  },
                },
              },

              initial: "inactive",
            },
          },
          type: "parallel",
        },
      },
      type: "parallel",
      context: {
        legend: {
          filterKeys: [],
          keys,
        },
        tooltip: {},
      },
      schema: {
        context: {} as ChartContext,
        events: {} as Event,
      },
      tsTypes: {} as import("./chart-machine.typegen").Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        setTooltipData: assign({
          tooltip: (ctx, event) => {
            if (event.type !== "TOOLTIP.OPEN") return ctx.tooltip;
            return {
              activeData: event.data,
              clientX: event.clientX,
              clientY: event.clientY,
              yValues: event.yValues,
            };
          },
        }),
        clearTooltipData: assign({
          tooltip: (_) => {
            return {};
          },
        }),
        addOrRemoveFilterKey: assign({
          legend: (ctx, event) => {
            if (event.type !== "LEGEND.CLICK") return ctx.legend;
            const { filterKeys, keys } = ctx.legend;
            const { key } = event;
            const index = filterKeys.indexOf(key);
            if (index === -1) {
              return { keys, filterKeys: [...filterKeys, key] };
            }
            return { keys, filterKeys: filterKeys.filter((k) => k !== key) };
          },
        }),
        setHoveredKey: assign({
          legend: (ctx, event) => {
            if (event.type !== "LEGEND.HOVER") return ctx.legend;
            const { legend } = ctx;
            return { ...legend, hoveredKey: event.key };
          },
        }),
        removeHoveredKey: assign({
          legend: (ctx) => {
            return { ...ctx.legend, hoveredKey: undefined };
          },
        }),
        setFocusKey: assign({
          legend: (ctx, event) => {
            if (event.type !== "LEGEND.DOUBLECLICK") return ctx.legend;
            const { legend } = ctx;
            return { ...legend, filterKeys: ctx.legend.keys.filter((k) => k !== event.key) };
          },
        }),
        setAllButKey: assign({
          legend: (ctx, event) => {
            if (event.type !== "LEGEND.DOUBLECLICK") return ctx.legend;
            const { legend } = ctx;
            return { ...legend, filterKeys: [event.key] };
          },
        }),
      },
      guards: {
        notFiltered: (ctx, event) => {
          if (event.type !== "LEGEND.HOVER") return false;
          return !ctx.legend.filterKeys.includes(event.key);
        },
        emptyFilters: (ctx) => {
          return ctx.legend.filterKeys.length === 0;
        },
        notInFocus: (ctx, event) => {
          if (event.type !== "LEGEND.DOUBLECLICK") return false;
          return !ctx.legend.filterKeys.includes(event.key);
        },
        isInFocus: (ctx, event) => {
          if (event.type !== "LEGEND.DOUBLECLICK") return false;
          return ctx.legend.filterKeys.includes(event.key);
        },
      },
    },
  );
