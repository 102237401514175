import { Bar as VBar } from "@visx/shape";

interface BarProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  active?: boolean;
}

export const Bar = ({ x, y, width, height, fill, active }: BarProps) => {
  const opacity = active === undefined ? 1 : active ? 1 : 0.3;
  if (height <= 0 || isNaN(height)) return null;
  return <VBar x={x} y={y} width={width} height={height} fill={fill} opacity={opacity} />;
};
