/**
 * Auto type a given collection of objects
 * This will turn string dates into JS date objects
 * And turn strings that look like numbers into numbers
 */
import { uniq } from "lodash-es";
import { DateTime } from "luxon";
import type { Data, DataType } from "../shared/types";

export function autoType(data: Data[], types?: { [key: string]: DataType }): Data[] {
  const keys = uniq(data.map((d) => Object.keys(d)).flat());
  const parsedData = data.map((d) => parseTypesByKey(d, keys, types));
  return parsedData;
}

function parseTypesByKey(data: Data, keys: string[], types?: { [key: string]: DataType }): Data {
  const parsed: Data = {};
  for (const key of keys) {
    const desiredType = types?.[key];
    if (desiredType === "date") {
      parsed[key] = parseDate(data[key] as string);
      continue;
    }
    if (desiredType === "string") {
      parsed[key] = data[key] as string;
      continue;
    }
    if (desiredType === "number") {
      parsed[key] = parseFloat(data[key] as string);
      continue;
    }
    parsed[key] = autoParse(data[key]);
  }
  return parsed;
}

export function autoParse(value: Data[string]): Data[string] {
  if (typeof value === "string" && value.length > 4) {
    return parseDate(value);
  }
  return value;
}

function parseDate(value: string) {
  if (DateTime.fromISO(value).isValid) {
    return DateTime.fromISO(value).toJSDate();
  }
  if (DateTime.fromSQL(value).isValid) {
    return DateTime.fromSQL(value).toJSDate();
  }
  return value;
}
