import {
  AxisLeft as VAxisLeft,
  AxisBottom as VAxisBottom,
  AxisRight as VAxisRight,
  type TickRendererProps,
} from "@visx/axis";
import { Text } from "@visx/text";
import { type AnyD3Scale, getTicks } from "@visx/scale";
import type { TickFormatter } from "@visx/axis";
import { identity } from "lodash-es";

export type AnyTickFormatter = TickFormatter<string> | TickFormatter<number> | TickFormatter<Date>;

interface AxisLeftProps {
  scale: AnyD3Scale;
  left?: number;
  top?: number;
  label?: string;
  numTicks?: number;
  tickFormat?: AnyTickFormatter;
}

interface AxisBottomProps {
  scale: AnyD3Scale;
  left?: number;
  numTicks?: number;
  top?: number;
  label?: string;
  width?: number;
  tickFormat?: AnyTickFormatter;
}

interface AxisRightProps {
  scale: AnyD3Scale;
  left?: number;
  numTicks?: number;
  top?: number;
  label?: string;
  tickFormat?: AnyTickFormatter;
}

const labelStyles = "font-body fill-gray-70 dark:fill-gray-20";
const tickClassName = "font-body fill-gray-70 dark:fill-gray-40";
const axisLineStyles = "stroke-gray-30 stroke-[1.5px] dark:stroke-gray-40";
const axisTickStyles = "stroke-gray-30 dark:stroke-gray-40";

export const AxisLeft = ({ left, top, scale, tickFormat, label, numTicks = 4 }: AxisLeftProps) => {
  return (
    <VAxisLeft
      left={left}
      top={top}
      axisLineClassName={axisLineStyles}
      tickLineProps={{ className: axisTickStyles }}
      scale={scale}
      tickFormat={tickFormat}
      label={label}
      numTicks={numTicks}
      hideAxisLine
      hideTicks
      labelClassName={labelStyles}
      tickClassName={tickClassName}
      tickLabelProps={() => ({
        className: tickClassName,
        textAnchor: "end",
        dy: "0.25em",
        dx: "-0.25em",
        fontSize: 10,
      })}
    />
  );
};

export const AxisBottom = ({ left, top, scale, tickFormat, label, numTicks }: AxisBottomProps) => {
  const _numTicks = numTicks ?? getApproxTicks(scale, tickFormat ?? identity);
  return (
    <VAxisBottom
      left={left}
      top={top}
      axisLineClassName={axisLineStyles}
      tickLineProps={{ className: axisTickStyles }}
      scale={scale}
      tickFormat={tickFormat}
      numTicks={_numTicks}
      label={label}
      tickComponent={TickLabel}
      labelClassName={labelStyles}
      tickClassName={tickClassName}
      tickLabelProps={() => ({
        className: tickClassName,
        textAnchor: "middle",
        dy: "0.25em",
        fontSize: 10,
      })}
    />
  );
};

const getApproxTicks = (scale: AnyD3Scale, tickFormat: AnyTickFormatter, maxTicks = 10) => {
  const ticks = getTicks(scale, maxTicks).map((value, index) => ({ value, index }));
  const [value] = scale.domain();
  if (value === null || value === undefined) return 0;
  const [, width] = scale.range();
  const approxLabelLength = (tickFormat(value as string & number, 0, ticks) ?? "").length * 8;
  const numTicks = Math.min(maxTicks, Math.floor(width / approxLabelLength));
  if (isNaN(numTicks)) return maxTicks;
  return numTicks;
};

export const AxisRight = ({ left, top, scale, tickFormat, label, numTicks = 4 }: AxisRightProps) => {
  return (
    <VAxisRight
      left={left}
      top={top}
      axisLineClassName={axisLineStyles}
      tickLineProps={{ className: axisTickStyles }}
      scale={scale}
      tickFormat={tickFormat}
      label={label}
      numTicks={numTicks}
      hideAxisLine
      hideTicks
      labelClassName={labelStyles}
      tickClassName={tickClassName}
      tickLabelProps={() => ({
        className: tickClassName,
        textAnchor: "start",
        dy: "0.25em",
        dx: "0.25em",
        fontSize: 10,
      })}
    />
  );
};

const TickLabel = ({ formattedValue, ...tickProps }: TickRendererProps) => {
  if (formattedValue === null || formattedValue === undefined) return "";
  return <Text {...tickProps}>{formattedValue}</Text>;
};
