import type { visualization } from "@fscrypto/domain";
import { ScatterChart, ParentSize } from "@fscrypto/viz";
import { getMargin } from "./shared";
import type { ChartProps } from "./types";
import { useSetChartColorMap } from "../settings/state";
import { createTypeOverrides } from "../settings/util";

interface ScatterChartProps extends ChartProps<visualization.ScatterChart> {}

export const ScatterChartVisualization = ({ chart, data, displayMode, legend, margin }: ScatterChartProps) => {
  const { xAxisValue, yAxisValue } = chart;
  const typeOverrides = createTypeOverrides({ ...chart, yAxisValues: [yAxisValue!] });
  const setChartColorMap = useSetChartColorMap();
  return (
    <ParentSize>
      {({ width, height }) => {
        if (width == 0 || !data.length) {
          return null;
        }
        return (
          <div className="h-full w-full ">
            <ScatterChart
              data={data}
              xKey={xAxisValue!}
              yKey={yAxisValue!}
              rKey={chart.markerColumn}
              colorKey={chart.colorValue}
              xAxisLabel={chart.xAxisLabel}
              xAxisSortDirection={chart.xAxisSortDirection}
              xAxisSortKey={chart.xAxisSortKey}
              yAxisLabel={chart.yAxisLabel}
              xAxisScale={chart.xAxisScale as "linear" | "log" | "time" | "band" | "auto"}
              yAxisScale={chart.yAxisScale as "linear" | "log" | "band" | "auto"}
              width={width}
              height={height}
              rRange={chart.markerScaleRange}
              margin={margin ?? getMargin(width)}
              displayMode={displayMode}
              showLegend={legend.show}
              legendPosition="top"
              maximumCategories={chart.maximumCategories}
              typeOverrides={typeOverrides}
              onChartOutputChange={(c) => setChartColorMap(c.colorMap)}
              colorMap={legend.colorMap}
              tooltip={chart.tooltip}
            />
          </div>
        );
      }}
    </ParentSize>
  );
};
