import { scaleLog } from "@visx/scale";

interface LogScaleConfig {
  nice?: boolean;
  clamp?: boolean;
}

export function createLogScale(
  [min, max]: [number, number],
  range: [number, number],
  { clamp = true, nice }: LogScaleConfig = {},
) {
  const adjustedMin = min <= 0 ? 0.001 : min;
  return scaleLog({
    domain: [adjustedMin, max],
    range,
    nice,
    clamp,
  });
}
