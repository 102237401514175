import { scaleTime } from "@visx/scale";

interface TimeScaleConfig {
  nice?: boolean;
  round?: boolean;
}
export function createTimeScale(
  dateRange: [Date, Date],
  range: [number, number],
  { nice, round }: TimeScaleConfig = {},
) {
  return scaleTime({
    domain: dateRange,
    range,
    nice,
    round,
  });
}
