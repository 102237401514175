import type { Data } from "../shared/types";
import type { TickFormatter } from "@visx/axis";
import { number, smartTruncate } from "../internal/formatters";
import { isTimeSeries } from "../internal/data";
import { formatDateFromData } from "../internal/formatters/date";
import { identity } from "lodash-es";

export function getXTickFormat(data: Data[], xKey: string): TickFormatter<Date> | TickFormatter<string> {
  if (isTimeSeries(data, xKey)) {
    return formatDateFromData(data, xKey);
  }
  return smartTruncate;
}

export function getYTickFormat([, max]: [number, number]): TickFormatter<number> {
  if (max > 1000) {
    return number;
  }
  return identity;
}
