import type { visualization } from "@fscrypto/domain";
import { BarChart, ParentSize } from "@fscrypto/viz";
import React from "react";
import { getMargin } from "./shared";
import type { ChartProps } from "./types";
import { useSetChartColorMap } from "../settings/state";
import { createTypeOverrides } from "../settings/util";

interface BarChartProps extends ChartProps<visualization.BarChart> {}

const BarChartViz = ({ chart, legend, data, displayMode, margin }: BarChartProps) => {
  const setChartColorMap = useSetChartColorMap();
  const typeOverrides = createTypeOverrides(chart);
  return (
    <div className="h-full w-full">
      <ParentSize>
        {({ width, height }) => (
          <BarChart
            data={data}
            xKey={chart.xAxisValue!}
            yKeys={chart.yAxisValues}
            height={height}
            width={width}
            groupingKey={chart.groupByValue}
            displayType={chart.displayType}
            xAxisLabel={chart.xAxisLabel}
            yAxisLabel={chart.yAxisLabel}
            yAxisLabelRight={chart.yAxisRightLabel}
            yAxisScale={chart.yAxisScale as "linear" | "log"}
            xAxisSortDirection={chart.xAxisSortDirection}
            xAxisSortKey={chart.xAxisSortKey}
            yKeyLine={chart.yAxisLineValue}
            yAxisLinePosition={chart.yAxisLinePosition}
            normalize={chart.normalize}
            showLegend={legend.show}
            legendPosition="top"
            margin={margin ?? getMargin(width)}
            displayMode={displayMode}
            maximumCategories={chart.maximumCategories}
            typeOverrides={typeOverrides}
            lineGap={chart.lineGap}
            onChartOutputChange={(c) => setChartColorMap(c.colorMap)}
            colorMap={legend.colorMap}
            tooltip={chart.tooltip}
          />
        )}
      </ParentSize>
    </div>
  );
};
export const BarChartVisualization = React.memo(BarChartViz);
