import type { Data } from "../shared/types";
import { type AnyD3Scale } from "@visx/scale";
import { GlyphCircle } from "@visx/glyph";
import { AreaClosed, AreaStack as VAreaStack } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";

interface AreaProps {
  data: Data[];
  xValue: string;
  yValue: string;
  yScale: AnyD3Scale;
  xScale: AnyD3Scale;
  colorScale: (x: string) => string;
  height: number;
  activeXValue?: Date;
  activeKey?: string;
}

export const Area = ({
  data,
  xValue,
  xScale,
  yValue,
  yScale,
  colorScale,
  height,
  activeXValue,
  activeKey,
}: AreaProps) => {
  return (
    <>
      <AreaClosed
        data={data}
        x={(d) => xScale(d[xValue] as Date | number)}
        yScale={yScale}
        fill={colorScale(yValue)}
        height={height}
        y={(d) => yScale(d[yValue] as number)}
        curve={curveMonotoneX}
        stroke="white"
        strokeOpacity={1}
        opacity={activeKey === yValue ? 1 : 0.5}
      />
      {data.map((d, i) => {
        const active = d[xValue] === activeXValue;
        return (
          <GlyphCircle
            key={`line-${yValue}-${i}`}
            left={xScale((d[xValue] as number | Date) ?? 0)}
            top={yScale((d[yValue] as number) ?? 0)}
            size={40}
            fill={active ? colorScale(yValue) : "transparent"}
            strokeWidth={10}
            opacity={1}
          />
        );
      })}
    </>
  );
};

interface AreaStackedProps {
  keys: string[];
  data: Data[];
  xValue: string;
  xScale: AnyD3Scale;
  yScale: AnyD3Scale;
  colorScale: (x: string) => string;
  activeXValue?: Date;
  activeKey?: string;
}
export const AreaStacked = ({
  data,
  keys,
  xValue,
  xScale,
  yScale,
  colorScale,
  activeXValue,
  activeKey,
}: AreaStackedProps) => {
  return (
    <>
      <VAreaStack
        data={data}
        keys={keys}
        x={(d) => xScale(d.data[xValue] as Date | number)}
        y0={(d) => yScale(d[0])}
        y1={(d) => yScale(d[1])}
        curve={curveMonotoneX}
      >
        {({ stacks, path }) =>
          stacks.map((stack) => (
            <>
              <path
                key={`stack-${stack.key}`}
                d={path(stack) || ""}
                fill={colorScale(stack.key)}
                stroke="white"
                strokeWidth={1}
                opacity={stack.key === activeKey ? 1 : 0.5}
              />
              {stack.map((d, i) => {
                const x = xScale(d.data[xValue] as Date | number) ?? 0;
                const y = yScale(d[1]) ?? 0;
                const active = d.data[xValue] === activeXValue;
                const transparent = d.data[stack.key] === 0;
                return (
                  <GlyphCircle
                    key={`area-${i}`}
                    left={x}
                    top={y}
                    size={40}
                    fill={transparent ? "transparent" : active ? colorScale(stack.key) : "transparent"}
                    strokeWidth={10}
                    opacity={1}
                  />
                );
              })}
            </>
          ))
        }
      </VAreaStack>
    </>
  );
};
