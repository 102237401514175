import { Group } from "@visx/group";
import { LegendOrdinal, useLegendStyles } from "../../legend";
import { ChartProps, Margin } from "../../shared/types";
import { CategoricalColorScale, ContinuousColorScale, isCategoricalScale, isContinuousScale } from "../../colors";
import { LegendContinuous } from "../../legend/legend-continuous";
import { ParentSize } from "../../..";
import { useAtom } from "jotai";
import { heightAtom, widthAtom } from "../atoms/dimensions";
import { useEffect } from "react";
import { zipObject } from "lodash-es";

export interface ChartWrapperProps extends ChartProps {
  colorScale?: ContinuousColorScale | CategoricalColorScale;
  filteredKeys?: string[];
  onLegendClick?: (key: string) => void;
  onLegendHover?: (key: string) => void;
  onLegendLeave?: () => void;
  top?: number;
  left?: number;
}
export const ChartWrapper = (props: React.PropsWithChildren<ChartWrapperProps>) => {
  const {
    showLegend = true,
    legendPosition = "top",
    displayMode = "normal",
    colorScale,
    filteredKeys = [],
    onLegendClick,
    onLegendHover,
    onLegendLeave,
    margin,
    width,
    height,
    children,
    top,
    left,
    onChartOutputChange,
  } = props;
  useEffect(() => {
    if (onChartOutputChange) {
      onChartOutputChange({
        colorMap:
          zipObject((colorScale?.domain() ?? []) as string[], (colorScale?.range() ?? []) as string[]) ??
          ({} as Record<string, string>),
      });
    }
  }, [onChartOutputChange, colorScale]);
  const { containerStyle, orientation } = useLegendStyles(legendPosition);
  if (width < 10 || height < 10) return null;
  return (
    <div className={containerStyle} style={{ width, height }}>
      {showLegend && displayMode === "normal" && colorScale && (
        <div className="scrollbar-hide overflow-y-scroll">
          {isCategoricalScale(colorScale) && (
            <LegendOrdinal
              colorScale={colorScale}
              orientation={orientation}
              filteredKeys={filteredKeys}
              onClick={onLegendClick}
              onHover={onLegendHover}
              onLeave={onLegendLeave}
            />
          )}
          {isContinuousScale(colorScale) && <LegendContinuous colorScale={colorScale} orientation={orientation} />}
        </div>
      )}
      <ParentSize>
        {({ width: svgWidth, height: svgHeight }) => (
          <SVGWrapper width={svgWidth} height={svgHeight} margin={margin} top={top} left={left}>
            {children}
          </SVGWrapper>
        )}
      </ParentSize>
    </div>
  );
};

interface SVGWrapperProps {
  width: number;
  height: number;
  margin: Margin;
  top?: number;
  left?: number;
  children: React.ReactNode;
}

const SVGWrapper = ({ width, height, margin, top, left, children }: SVGWrapperProps) => {
  const [, setWidth] = useAtom(widthAtom);
  const [, setHeight] = useAtom(heightAtom);
  useEffect(() => {
    setWidth(width);
    setHeight(height);
  }, [width, height, setWidth, setHeight]);
  return (
    <svg width={width} height={height}>
      <Group top={top ?? margin.top} left={left ?? margin.left}>
        {children}
      </Group>
    </svg>
  );
};
