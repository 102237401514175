export function smartTruncate(str: string): string {
  try {
    if (str.length > 30 && !str.includes(" ")) {
      return str.slice(0, 10) + "...";
    }
    return str;
  } catch {
    return str;
  }
}
