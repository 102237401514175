import type { ChartType } from "./new";
import { metadataMap } from "./new";

export const EmptyChartPrompt = ({ chartType }: { chartType: ChartType }) => {
  const label = metadataMap(200, 200)[chartType]?.label;
  const article = label?.match(/^[aeiou]/i) ? "an" : "a";
  const icon = metadataMap(200, 200)[chartType]?.icon;

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex items-center">
        <div className="opacity-30">{icon}</div>
        <div>
          <h2 className="text-gray-60 mb-2 text-lg font-semibold dark:text-gray-300">
            To create {article} {label}, choose:
          </h2>
          <ul className="max-w-md list-inside list-disc space-y-1 text-gray-50 dark:text-gray-400">
            {metadataMap()[chartType]?.hints.map((hint) => (
              <li key={hint}>{hint}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
