import { createStore, Provider, useAtom } from "jotai";
import { localPoint } from "@visx/event";

import { Line } from "./line";
import { Tooltip } from "../tooltip/tooltip";
import state, { chartMachineAtom, configAtom } from "./state";
import { LineTooltipOverlay } from "./line-tooltip-overlay";
import { DashedLine } from "../internal/components/dashed-line";
import { XYChartProps } from "../shared/types";
import { XYChartWrapper } from "../internal/components/xy-chart";
import { ScaleType } from "../scales";
import { useRunOnce } from "../internal/hooks/useRunOnce";
import { useEffect } from "react";
import { useConstant } from "../internal/hooks";

export interface LineChartProps extends XYChartProps {
  xAxisScale?: Exclude<ScaleType, "log">;
  yAxisScale?: Extract<ScaleType, "linear" | "log">;
}

export const LineChart = (props: LineChartProps) => {
  const { displayMode = "normal" } = props;
  const innerHeight = state.useInnerHeight();
  const data = state.useData();
  const dataRight = state.useDataRight();
  const xKey = state.useXKey();
  const keys = state.useKeys();
  const keysRight = state.useKeysRight();
  const allKeys = state.useAllKeys();
  const xScale = state.useXScale();
  const yScale = state.useYScale();
  const yScaleRight = state.useYScaleRight();
  const yTickFormat = state.useYTickFormat();
  const xTickFormat = state.useXTickFormat();
  const colorScale = state.useColorScale();
  const [machineState, send] = useAtom(chartMachineAtom);

  return (
    <div>
      <XYChartWrapper
        {...props}
        xScale={xScale}
        yScale={yScale}
        xTickFormatter={xTickFormat}
        yTickFormatter={yTickFormat}
        yScaleRight={yScaleRight}
        yTickFormatterRight={yTickFormat}
        colorScale={colorScale}
        onLegendClick={(key) => send({ type: "LEGEND.CLICK", key })}
        onLegendHover={(key) => send({ type: "LEGEND.HOVER", key })}
        onLegendLeave={() => send("LEGEND.LEAVE")}
        filteredKeys={machineState.context?.legend?.filterKeys}
      >
        <>
          {keys.map((key) => (
            <Line
              key={key}
              data={data}
              xScale={xScale}
              xValue={xKey}
              yScale={yScale}
              yValue={key}
              colorScale={colorScale}
              activeXValue={machineState.context?.tooltip?.activeData?.[xKey] as Date}
              active={!machineState.context?.legend.hoveredKey || machineState.context?.legend.hoveredKey === key}
              strokeWidth={displayMode === "preview" ? 6 : undefined}
              lineGap={props.lineGap}
              displayMode={displayMode}
            />
          ))}
          {dataRight.length &&
            keysRight.map((key) => (
              <Line
                key={key}
                data={dataRight}
                xScale={xScale}
                xValue={xKey}
                yScale={yScaleRight!}
                yValue={key}
                colorScale={colorScale}
                activeXValue={machineState.context?.tooltip?.activeData?.[xKey] as Date}
                active={!machineState.context?.legend.hoveredKey || machineState.context?.legend.hoveredKey === key}
                strokeWidth={displayMode === "preview" ? 6 : undefined}
                lineGap={props.lineGap}
                displayMode={displayMode}
              />
            ))}
          <LineTooltipOverlay
            onMouseMove={(event, data) => {
              if (!data) return;
              const coords = localPoint(event);
              send({ type: "TOOLTIP.OPEN", data, clientX: coords?.x ?? 0, clientY: coords?.y ?? 0 });
            }}
            onMouseLeave={() => send("TOOLTIP.CLOSE")}
          />
          {machineState.matches("tooltip.opened") && displayMode === "normal" && (
            <DashedLine
              x0={xScale(machineState.context.tooltip.activeData![xKey] as string & number)!}
              x1={xScale(machineState.context.tooltip.activeData![xKey] as string & number)!}
              y0={0}
              y1={innerHeight}
            />
          )}
        </>
      </XYChartWrapper>
      {!machineState.matches("tooltip.closed") && displayMode === "normal" && (
        <Tooltip
          data={machineState.context.tooltip.activeData!}
          xValue={xKey}
          yValues={allKeys}
          xFormatter={xTickFormat}
          colorScale={colorScale}
          left={machineState.context.tooltip.clientX ?? 0}
          top={machineState.context.tooltip.clientY ?? 0}
          hideZeros={props.tooltip?.hideZeros ?? false}
        />
      )}
    </div>
  );
};

const LineChartProvider = (props: LineChartProps) => {
  const store = useConstant(() => createStore());
  useRunOnce(() => {
    store.set(configAtom, props);
  });
  useEffect(() => {
    store.set(configAtom, props);
  }, [props, store]);
  return (
    <Provider store={store}>
      <LineChart {...props} />
    </Provider>
  );
};

export default LineChartProvider;
