import type { visualization } from "@fscrypto/domain";
import type { Data, DisplayMode, Margin } from "@fscrypto/viz";
import { ParentSize, AreaChart } from "@fscrypto/viz";
import { useSetChartColorMap } from "../settings/state";
import { createTypeOverrides } from "../settings/util";
import { getMargin } from "./shared";

interface AreaChartProps {
  chart: visualization.AreaChart;
  legend: visualization.Legend;
  data: Data[];
  showLegend?: boolean;
  displayMode?: DisplayMode;
  margin?: Margin;
}

export const AreaChartVisualization = ({ chart, data, legend, displayMode, margin }: AreaChartProps) => {
  const setChartColorMap = useSetChartColorMap();
  const typeOverrides = createTypeOverrides(chart);
  return (
    <ParentSize>
      {({ height, width }) => (
        <AreaChart
          width={width}
          height={height}
          data={data}
          xKey={chart.xAxisValue!}
          xAxisLabel={chart.xAxisLabel}
          yKeys={chart.yAxisValues}
          yAxisLabel={chart.yAxisLabel}
          yAxisScale={chart.yAxisScale as "linear" | "log" | undefined}
          showLegend={legend.show}
          legendPosition="top"
          normalize={chart.normalize}
          groupingKey={chart.groupByValue}
          displayType={chart.displayType}
          margin={margin ?? getMargin(width)}
          displayMode={displayMode}
          maximumCategories={chart.maximumCategories}
          typeOverrides={typeOverrides}
          xAxisSortDirection={chart.xAxisSortDirection}
          onChartOutputChange={(c) => setChartColorMap(c.colorMap)}
          colorMap={legend.colorMap}
        />
      )}
    </ParentSize>
  );
};
