import { ErrorBoundary, type ErrorBoundaryProps } from "react-error-boundary";
import React from "react";
import * as Sentry from "@sentry/remix";

type ResetProps = ErrorBoundaryProps & {
  children: React.ReactNode;
};

const ResettableErrorBoundary = ({ children, ...props }: ResetProps) => {
  return (
    <ErrorBoundary {...props} onError={(e) => Sentry.captureException(e)}>
      {children}
    </ErrorBoundary>
  );
};

export default ResettableErrorBoundary;
