import { curveLinear, curveMonotoneX } from "@visx/curve";
import { GlyphCircle } from "@visx/glyph";
import type { AnyD3Scale, PickD3Scale } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { isEqual } from "date-fns";
import { useCallback } from "react";
import type { Data } from "../shared/types";

interface LineProps {
  data: Data[];
  xValue: string;
  yValue: string;
  xScale: AnyD3Scale;
  yScale: AnyD3Scale;
  colorScale: PickD3Scale<"ordinal", string, string>;
  active?: boolean;
  activeXValue?: Date;
  strokeWidth?: number;
  curve?: "linear" | "monotoneX";
  paddingFactor?: number;
  lineGap?: "nulls" | "zeros" | "none";
  displayMode?: "normal" | "preview" | "bare";
}

export const Line = ({
  data,
  xScale,
  xValue,
  yValue,
  yScale,
  colorScale,
  activeXValue,
  active = true,
  strokeWidth = 2,
  curve = "linear",
  paddingFactor = 0,
  lineGap = "none",
  displayMode = "normal",
}: LineProps) => {
  const color = colorScale(yValue);
  const opacity = active ? 1 : 0.3;
  const lineGapFunc = useCallback(
    (d: Data) => {
      if (lineGap === "none") return true;
      if (lineGap === "nulls") return d[yValue] !== null;
      return d[yValue] !== 0 && d[yValue] !== null;
    },
    [lineGap, yValue],
  );
  return (
    <>
      <LinePath
        data={data}
        x={(d) => xScale((d[xValue] as number | Date) ?? 0) + paddingFactor}
        y={(d) => yScale((d[yValue] as number) ?? 0)}
        defined={lineGapFunc}
        curve={curve === "linear" ? curveLinear : curveMonotoneX}
        stroke={color}
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
      {data.map((d, i) => {
        const showGlyph = active && isEqual(d[xValue] as Date, activeXValue!) && displayMode === "normal";
        return (
          <GlyphCircle
            key={`line-${yValue}-${i}`}
            left={xScale((d[xValue] as number | Date) ?? 0) + paddingFactor}
            top={yScale((d[yValue] as number) ?? 0)}
            size={40}
            fill={showGlyph ? color : "transparent"}
            strokeWidth={10}
            opacity={opacity}
          />
        );
      })}
    </>
  );
};
