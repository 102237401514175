import { sumBy, zipObject } from "lodash-es";
import type { Data } from "../../shared/types";

export function normalize(data: Data[], xValue: string): Data[] {
  const keys = Array.from(new Set(data.flatMap((d) => Object.keys(d).filter((k) => k !== xValue))));
  const normalized = data.map((d) => {
    const sum = sumBy(keys, (k) => d[k] as number);
    return zipObject(
      [xValue, ...keys],
      [d[xValue], ...keys.map((k) => ((d[k] as number) === 0 ? 0 : (d[k] as number) / sum))],
    ) as Data;
  });
  return normalized;
}
