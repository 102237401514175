import { Line } from "@visx/shape";
interface DashedLineProps {
  x0: number;
  x1: number;
  y0: number;
  y1: number;
}

export const DashedLine = ({ x0, x1, y0, y1 }: DashedLineProps) => {
  return (
    <Line
      from={{ x: x0, y: y0 }}
      to={{ x: x1, y: y1 }}
      stroke={"#B9B9B9"}
      strokeWidth={2}
      pointerEvents="none"
      strokeDasharray="5,2"
    />
  );
};
