import { Atom, atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { ChartProps, XYChartProps } from "../../shared/types";
import { heightAtom, widthAtom } from "./dimensions";

export const chartPropsToAtoms = (config?: Atom<ChartProps>) => {
  const configAtom = config ?? atom<ChartProps>({} as ChartProps);
  return {
    configAtom,
    rawDataAtom: selectAtom(configAtom, (v) => v.data),
    marginAtom: selectAtom(configAtom, (v) => v.margin),
    maximumCategoriesAtom: selectAtom(configAtom, (v) => v.maximumCategories),
    innerWidthAtom: atom((get) => {
      const width = get(widthAtom);
      const margin = get(configAtom).margin;
      return width - margin.left - margin.right;
    }),
    innerHeightAtom: atom((get) => {
      const height = get(heightAtom);
      const margin = get(configAtom).margin;
      return height - margin.top - margin.bottom;
    }),
  };
};

export const xyChartPropsToAtoms = <T extends XYChartProps>() => {
  const configAtom = atom<T>({} as T);
  const chartAtoms = chartPropsToAtoms(configAtom);
  return {
    ...chartAtoms,
    configAtom,
    xKeyAtom: selectAtom(configAtom, (v) => v.xKey),
    yKeysAtom: selectAtom(configAtom, (v) => v.yKeys),
    yKeysRightAtom: selectAtom(configAtom, (v) => v.yKeysRight),
    groupingKeyAtom: selectAtom(configAtom, (v) => v.groupingKey),
    yAxisScaleTypeAtom: selectAtom(configAtom, (v) => v.yAxisScale),
    yAxisRightScaleTypeAtom: selectAtom(configAtom, (v) => v.yAxisRightScale),
    xAxisScaleTypeAtom: selectAtom(configAtom, (v) => v.xAxisScale),
  };
};
