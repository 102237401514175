import { AnyD3Scale } from "@visx/scale";
import { AxisBottom, AxisLeft } from "../../axis";
import { GridRows } from "../../grid";
import { XYChartProps } from "../../shared/types";
import { AnyTickFormatter, AxisRight } from "../../axis/axis";
import { ChartWrapper, ChartWrapperProps } from "./chart";
import { useAtomValue } from "jotai";
import { heightAtom, widthAtom } from "../atoms/dimensions";

interface XYChartWrapperProps extends XYChartProps, ChartWrapperProps {
  yScale: AnyD3Scale;
  yScaleRight?: AnyD3Scale;
  xScale: AnyD3Scale;
  xTickFormatter: AnyTickFormatter;
  yTickFormatter: AnyTickFormatter;
  yTickFormatterRight?: AnyTickFormatter;
  xAxisOffset?: number;
}

export const XYChartWrapper = (props: React.PropsWithChildren<XYChartWrapperProps>) => {
  const {
    margin,
    children,
    displayMode = "normal",
    yScale,
    yScaleRight,
    xScale,
    yTickFormatter,
    xTickFormatter,
    yTickFormatterRight,
    xKey,
    yKeys,
    yKeysRight,
    xAxisOffset = 0,
  } = props;
  const width = useAtomValue(widthAtom);
  const height = useAtomValue(heightAtom);
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // Override numTicks if we're dealing with log scales
  const numTicks = props.yAxisScale === "log" ? Math.max(Math.floor(Math.log10(yScale.domain()[1])), 2) : undefined;
  const numTicksRight =
    props.yAxisRightScale === "log" ? Math.max(Math.floor(Math.log10(yScaleRight?.domain()[1] ?? 1)), 2) : undefined;
  const numTicksBottom =
    props.xAxisScale === "log" ? Math.max(Math.floor(Math.log10(xScale.domain()[1] ?? 1)), 2) : undefined;

  return (
    <ChartWrapper {...props}>
      {displayMode === "normal" && <GridRows scale={yScale} width={innerWidth} numTicks={numTicks} />}
      {displayMode === "normal" && (
        <>
          <AxisBottom
            scale={xScale}
            top={innerHeight + xAxisOffset}
            label={props.xAxisLabel ?? xKey}
            tickFormat={xTickFormatter}
            width={innerWidth}
            numTicks={numTicksBottom}
          />
          <AxisLeft
            scale={yScale}
            label={props.yAxisLabel ?? yKeys.join(",")}
            tickFormat={yTickFormatter}
            numTicks={numTicks}
          />
          {yScaleRight && (
            <AxisRight
              scale={yScaleRight}
              label={props.yAxisLabelRight ?? (yKeysRight ?? []).join(",")}
              tickFormat={yTickFormatterRight!}
              left={innerWidth}
              numTicks={numTicksRight}
            />
          )}
        </>
      )}
      {children}
    </ChartWrapper>
  );
};
