import { localPoint } from "@visx/event";

import { Area, AreaStacked } from "./area";
import { Tooltip } from "../tooltip/tooltip";
import { createStore, Provider, useAtom } from "jotai";
import state, { chartMachineAtom, propsAtom } from "./state";
import { AreaTooltipOverlay } from "./area-tooltip-overlay";
import { DashedLine } from "../internal/components/dashed-line";
import { XYChartProps } from "../shared/types";
import { XYChartWrapper } from "../internal/components/xy-chart";
import { useEffect } from "react";
import { useRunOnce } from "../internal/hooks/useRunOnce";
import { useConstant } from "../internal/hooks";

export interface AreaChartProps extends XYChartProps {
  displayType?: "stacked" | "overlay";
  normalize?: boolean;
  yAxisScale?: "auto" | "linear" | "log";
}

const AreaChart = (props: AreaChartProps) => {
  const { displayType = "stacked" } = props;
  const data = state.useData();
  const colorScale = state.useColorScale();
  const innerHeight = state.useInnerHeight();
  const xKey = state.useXKey();
  const keys = state.useKeys();
  const xScale = state.useXScale();
  const yScale = state.useYScale();
  const yKeys = state.useYKeys();
  const yTickFormat = state.useYTickFormat();
  const xTickFormat = state.useXTickFormat();
  const displayKeys = state.useDisplayKeys();
  const [machine, send] = useAtom(chartMachineAtom);

  return (
    <div>
      <XYChartWrapper
        {...props}
        xScale={xScale}
        yScale={yScale}
        xTickFormatter={xTickFormat}
        yTickFormatter={yTickFormat}
        yKeys={yKeys}
        onLegendClick={(key) => send({ type: "LEGEND.CLICK", key })}
        onLegendHover={(key) => send({ type: "LEGEND.HOVER", key })}
        onLegendLeave={() => send("LEGEND.LEAVE")}
        filteredKeys={machine.context.legend.filterKeys}
        colorScale={colorScale}
      >
        <>
          {machine.matches("tooltip.opened") && (
            <DashedLine
              x0={xScale(machine.context.tooltip.activeData?.[xKey] as Date)}
              y0={0}
              x1={xScale(machine.context.tooltip.activeData?.[xKey] as Date)}
              y1={innerHeight}
            />
          )}
          {displayType === "stacked" && (
            <AreaStacked
              data={data}
              colorScale={colorScale}
              keys={displayKeys}
              xValue={xKey}
              xScale={xScale}
              yScale={yScale}
              activeXValue={machine.context.tooltip.activeData?.[xKey] as Date}
              activeKey={machine.context.legend.hoveredKey}
            />
          )}
          {displayType === "overlay" &&
            displayKeys.map((k) => (
              <Area
                data={data}
                colorScale={colorScale}
                height={innerHeight}
                xValue={xKey}
                xScale={xScale}
                yValue={k}
                yScale={yScale}
                key={k}
                activeXValue={machine.context.tooltip.activeData?.[xKey] as Date}
                activeKey={machine.context.legend.hoveredKey}
              />
            ))}
          <AreaTooltipOverlay
            onMouseMove={(event, data) => {
              if (!data) return;
              const coords = localPoint(event);
              if (!coords) return;
              send({ type: "TOOLTIP.OPEN", data, clientX: coords.x, clientY: coords.y });
            }}
            onMouseLeave={() => send("TOOLTIP.CLOSE")}
          />
        </>
      </XYChartWrapper>
      {machine.matches("tooltip.opened") && (
        <Tooltip
          data={machine.context.tooltip.activeData!}
          xValue={xKey}
          xFormatter={xTickFormat}
          yValues={keys}
          colorScale={colorScale}
          left={machine.context.tooltip.clientX ?? 0}
          top={machine.context.tooltip.clientY ?? 0}
          hideZeros={props.tooltip?.hideZeros ?? false}
        />
      )}
    </div>
  );
};

const AreaChartProvider = (props: AreaChartProps) => {
  const store = useConstant(() => createStore());
  useRunOnce(() => {
    store.set(propsAtom, props);
  });
  useEffect(() => {
    store.set(propsAtom, props);
  }, [props, store]);
  return (
    <Provider store={store}>
      <AreaChart {...props} />
    </Provider>
  );
};

export default AreaChartProvider;
