import { Bar } from "./bar";
import state from "./state";

export const BarSimple = ({ activeKey }: { activeKey?: string | Date }) => {
  const data = state.useData();
  const xScale = state.useXScale();
  const yScale = state.useYScale();
  const colorScale = state.useColorScale();
  const yKeys = state.useYKeysLeft();
  const xKey = state.useXKey();

  return (
    <>
      {data.map((d) => {
        const key = yKeys[0]!;
        const barWidth = xScale.bandwidth();
        const value = d[key] as number;
        const x = xScale(d[xKey] as string & Date) ?? 0;
        const y = Math.min(yScale(value), yScale(0));
        const height = Math.abs(yScale(value) - yScale(0));
        const fill = colorScale(key);
        const active = !activeKey || activeKey.toString() === d[xKey]?.toString();
        return <Bar key={`bar-${d[xKey]}`} x={x} y={y} width={barWidth} height={height} fill={fill} active={active} />;
      })}
    </>
  );
};
