import type { visualization } from "@fscrypto/domain";
import { PieChart, ParentSize } from "@fscrypto/viz";
import { useSetChartColorMap } from "../settings/state";
import { getMargin } from "./shared";
import type { ChartProps } from "./types";

interface DonutChartProps extends ChartProps<visualization.Donut> {}

export const Donut = ({ data, chart, displayMode, legend, margin }: DonutChartProps) => {
  const setChartColorMap = useSetChartColorMap();
  return (
    <ParentSize>
      {({ width, height }) => (
        <PieChart
          data={data}
          height={height}
          width={width}
          labelKey={chart.labelKey as string}
          valueKey={chart.valueKey as string}
          labelOption={chart.labelOption}
          holeSize={chart.holeSize}
          maxSlices={chart.maxSlices ?? undefined}
          groupAdditionalSlices={chart.hasOtherSlice}
          margin={margin ?? getMargin(width)}
          showLegend={legend.show}
          legendPosition="top"
          displayMode={displayMode}
          maximumCategories={chart.maximumCategories}
          onChartOutputChange={(c) => setChartColorMap(c.colorMap)}
          colorMap={legend.colorMap}
          tooltip={chart.tooltip}
        />
      )}
    </ParentSize>
  );
};
