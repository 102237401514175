import { format } from "d3";

export function formatNumber(num: number, overrideDecimalCount?: number): string {
  // if passed an override decimal count, use that no matter what
  // check for undefined because 0 is a valid override
  const getDecimals = (count: number): number => {
    if (overrideDecimalCount !== undefined) {
      return overrideDecimalCount;
    } else {
      return count;
    }
  };

  let decimals = getDecimals(1);

  let formatString = "";
  let symbol = "";
  if (Math.abs(num) >= 1e9) {
    num = num / 1e9;
    formatString = `,.${decimals}f`;
    symbol = "b";
  } else if (Math.abs(num) >= 1e6) {
    num = num / 1e6;
    formatString = `,.${decimals}f`;
    symbol = "m";
  } else if (Math.abs(num) >= 1e3) {
    num = num / 1e3;
    formatString = `,.${decimals}f`;
    symbol = "k";
  } else if (Math.abs(num) < 1e-6 && Math.abs(num) > 0) {
    num = num * 1e6;
    formatString = `,.${decimals}f`;
    symbol = "µ";
  } else {
    if (Math.abs(num) < 1 && Math.abs(num) >= 0.1) {
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.1 && Math.abs(num) >= 0.01) {
      decimals = getDecimals(2);
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.01 && Math.abs(num) >= 0.001) {
      decimals = getDecimals(3);
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.001 && Math.abs(num) >= 0.0001) {
      decimals = getDecimals(4);
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.0001 && Math.abs(num) >= 0.00001) {
      decimals = getDecimals(5);
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.00001 && Math.abs(num) >= 0.000001) {
      decimals = getDecimals(6);
      formatString = `,.${decimals}f`;
    } else {
      formatString = `,.${decimals}f`;
    }
  }

  // this will remove trailing zeros
  const formattedNum = format(formatString)(num);
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  return formattedNum.replace(regex, "$1") + symbol;
}
