import type { AxisScale } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Circle } from "@visx/shape";
import { Provider, createStore, useAtom } from "jotai";
import { useEffect } from "react";
import { DashedLine } from "../internal/components/dashed-line";
import { XYChartWrapper } from "../internal/components/xy-chart";
import { useConstant } from "../internal/hooks";
import { useRunOnce } from "../internal/hooks/useRunOnce";
import type { Data, XYChartProps } from "../shared/types";
import { Tooltip } from "../tooltip/tooltip";
import state, { chartMachineAtom, configAtom } from "./state";

export interface ScatterChartProps extends Omit<XYChartProps, "yKeys"> {
  xAxisScale?: "linear" | "log" | "time" | "band" | "auto";
  yAxisScale?: "linear" | "log" | "band" | "auto";
  yKey: string;
  rKey?: string;
  rRange?: number;
  colorKey?: string;
}

export function ScatterChart(props: ScatterChartProps) {
  const [machine, send] = useAtom(chartMachineAtom);
  const { colorKey } = props;
  const data = state.useData();
  const xTickFormat = state.useXTickFormatter();
  const yTickFormat = state.useYTickFormatter();
  const xScale = state.useXScale();
  const yScale = state.useYScale();
  const innerHeight = state.useInnerHeight();
  const xKey = state.useXKey();
  const rKey = state.useRKey();
  const yKey = state.useYKey();
  const colorScale = state.useColorScale();
  const rScale = state.useRScale();
  return (
    <div>
      <XYChartWrapper
        {...props}
        yScale={yScale}
        yKeys={[yKey]}
        xScale={xScale}
        xTickFormatter={xTickFormat}
        yTickFormatter={yTickFormat}
        colorScale={colorScale}
        filteredKeys={machine.context.legend.filterKeys}
        onLegendClick={(key) => send({ type: "LEGEND.CLICK", key })}
        onLegendHover={(key) => send({ type: "LEGEND.HOVER", key })}
        onLegendLeave={() => send("LEGEND.LEAVE")}
      >
        {machine.matches("tooltip.opened") && (
          <>
            <DashedLine
              x0={xScale(machine.context.tooltip.activeData![xKey] as number & string)!}
              x1={xScale(machine.context.tooltip.activeData![xKey] as number & string)!}
              y0={yScale(machine.context.tooltip.activeData![yKey] as number & string)!}
              y1={innerHeight}
            />
            <DashedLine
              x0={0}
              x1={xScale(machine.context.tooltip.activeData![xKey] as number & string)!}
              y0={yScale(machine.context.tooltip.activeData![yKey] as number & string)!}
              y1={yScale(machine.context.tooltip.activeData![yKey] as number & string)!}
            />
          </>
        )}
        {data.map((point, i) => {
          const fill = colorKey ? colorScale(point[colorKey] as string & number) : colorScale(yKey as string & number);
          const hovered = machine.context.legend.hoveredKey || machine.context.tooltip.activeData;
          const active =
            !hovered ||
            machine.context.tooltip.activeData?.[xKey] === point[xKey] ||
            machine.context.legend.hoveredKey === point[colorKey!];
          const opacity = active ? 0.8 : 0.2;
          const cx = getScaledValueFactory(xScale, xKey)(point);
          const cy = getScaledValueFactory(yScale, yKey)(point);
          return (
            <Circle
              key={i}
              cx={cx}
              cy={cy}
              r={rKey ? getScaledValueFactory(rScale, rKey)(point) : 5}
              fill={fill}
              fillOpacity={0.8}
              stroke={"white"}
              strokeWidth={1}
              strokeOpacity={1}
              opacity={opacity}
              onMouseEnter={(e) => {
                const { x, y } = localPoint(e) || { x: 0, y: 0 };
                send({ type: "TOOLTIP.OPEN", clientX: x, clientY: y, data: point });
              }}
              onMouseLeave={() => send("TOOLTIP.CLOSE")}
            />
          );
        })}
      </XYChartWrapper>
      {machine.context.tooltip.activeData && (
        <Tooltip
          xFormatter={xTickFormat}
          colorScale={colorScale}
          data={machine.context.tooltip.activeData}
          xValue={xKey}
          yValues={[yKey]}
          colorValue={colorKey}
          top={machine.context.tooltip.clientY ?? 0}
          left={machine.context.tooltip.clientX ?? 0}
          hideZeros={props.tooltip?.hideZeros ?? false}
        />
      )}
    </div>
  );
}

const ProviderScatterChart = (props: ScatterChartProps) => {
  const store = useConstant(() => createStore());
  useRunOnce(() => {
    store.set(configAtom, props);
  });
  useEffect(() => {
    store.set(configAtom, props);
  }, [props, store]);
  return (
    <Provider store={store}>
      <ScatterChart {...props} />
    </Provider>
  );
};

export default ProviderScatterChart;

function getScaledValueFactory<Scale extends AxisScale>(
  scale: Scale,
  accessorValue: string,
  align: "start" | "center" | "end" = "center",
) {
  return (d: Data) => {
    const scaledValue = scale(d[accessorValue] as Date | number);
    if (isValidNumber(scaledValue)) {
      const bandwidthOffset = (align === "start" ? 0 : getScaleBandwidth(scale)) / (align === "center" ? 2 : 1);
      return scaledValue + bandwidthOffset;
    }
    return NaN;
  };
}

function getScaleBandwidth<Scale extends AxisScale>(scale?: Scale) {
  // Broaden type before using 'xxx' in s as typeguard.
  const s = scale as AxisScale;
  return s && "bandwidth" in s ? s?.bandwidth() ?? 0 : 0;
}

function isValidNumber(_: unknown): _ is number {
  return _ != null && typeof _ === "number" && !Number.isNaN(_) && Number.isFinite(_);
}
