import { extent, timeDay, timeMonth, timeYear } from "d3";
import {
  formatISO,
  isEqual,
  startOfWeek,
  isFirstDayOfMonth,
  startOfDay,
  startOfYear,
  startOfHour,
  startOfMinute,
  addDays,
  addMonths,
  addYears,
} from "date-fns";
import { keyBy, zipObject, sumBy } from "lodash-es";
import type { Data } from "../../shared/types";

export function isTimeSeries(data: Data[], xValue: string): boolean {
  return data.every((d) => d[xValue] instanceof Date);
}

export function allDates(data: unknown[]): data is Date[] {
  return data.every((d) => d instanceof Date);
}

export function fillMissingDates(data: Data[], xValue: string): Data[] {
  if (data.length === 0) return data;
  const dataMap = keyBy(data, (d) => formatISO(d[xValue] as Date));
  const keys = Array.from(new Set(data.flatMap((d) => Object.keys(d).filter((k) => k !== xValue))));
  const [min, max] = extent(data.map((d) => d[xValue] as Date)) as [Date, Date];

  const defaultObject = zipObject(
    keys,
    keys.map(() => null),
  );
  if (isWeeklyData(data, xValue)) return fillWeeks(dataMap, [min, max], xValue, defaultObject);
  if (isMonthlyData(data, xValue)) return fillMonths(dataMap, [min, max], xValue, defaultObject);
  if (isYearlyData(data, xValue)) return fillYears(dataMap, [min, max], xValue, defaultObject);
  if (isDailyData(data, xValue)) return fillDays(dataMap, [min, max], xValue, defaultObject);
  return data;
}

export function isWeeklyData(data: Data[], xValue: string, threshold = 0.8): boolean {
  const dates = data.map((d) => d[xValue] as Date);
  const hits = sumBy(dates, (d) => (isEqual(d, startOfWeek(d, { weekStartsOn: 1 })) ? 1 : 0));
  return hits / dates.length > threshold;
}

export function isMonthlyData(data: Data[], xValue: string, threshold = 0.8): boolean {
  const dates = data.map((d) => d[xValue] as Date);
  const hits = sumBy(dates, (d) => (isFirstDayOfMonth(d) ? 1 : 0));
  return hits / dates.length > threshold;
}

export function isDailyData(data: Data[], xValue: string, threshold = 0.8): boolean {
  const dates = data.map((d) => d[xValue] as Date);
  const hits = sumBy(dates, (d) => (isEqual(d, startOfDay(d)) ? 1 : 0));
  return hits / dates.length > threshold;
}

export function isYearlyData(data: Data[], xValue: string, threshold = 0.8): boolean {
  const dates = data.map((d) => d[xValue] as Date);
  const hits = sumBy(dates, (d) => (isEqual(d, startOfYear(d)) ? 1 : 0));
  return hits / dates.length > threshold;
}

export function isHourlyData(data: Data[], xValue: string, threshold = 0.8): boolean {
  const dates = data.map((d) => d[xValue] as Date);
  const hits = sumBy(dates, (d) => (isEqual(d, startOfHour(d)) ? 1 : 0));
  return hits / dates.length > threshold;
}

export function isMinuteData(data: Data[], xValue: string, threshold = 0.8): boolean {
  const dates = data.map((d) => d[xValue] as Date);
  const hits = sumBy(dates, (d) => (isEqual(d, startOfMinute(d)) ? 1 : 0));
  return hits / dates.length > threshold;
}

export function fillWeeks(
  dataMap: Record<string, Data>,
  [min, max]: [Date, Date],
  xValue: string,
  defaultObject: Data,
): Data[] {
  const filledDates = timeDay.range(min, addDays(max, 1), 7);
  return filledDates.map((d) => {
    const value = dataMap[formatISO(d)];
    if (!value) return { ...defaultObject, [xValue]: d };
    return value;
  });
}

export function fillDays(
  dataMap: Record<string, Data>,
  [min, max]: [Date, Date],
  xValue: string,
  defaultObject: Data,
): Data[] {
  const filledDates = timeDay.range(min, addDays(max, 1), 1);
  return filledDates.map((d) => {
    const value = dataMap[formatISO(d)];
    if (!value) return { ...defaultObject, [xValue]: d };
    return value;
  });
}

export function fillMonths(
  dataMap: Record<string, Data>,
  [min, max]: [Date, Date],
  xValue: string,
  defaultObject: Data,
): Data[] {
  const filledDates = timeMonth.range(min, addMonths(max, 1));
  return filledDates.map((d) => {
    const value = dataMap[formatISO(d)];
    if (!value) return { ...defaultObject, [xValue]: d };
    return value;
  });
}

export function fillYears(
  dataMap: Record<string, Data>,
  [min, max]: [Date, Date],
  xValue: string,
  defaultObject: Data,
): Data[] {
  const filledDates = timeYear.range(min, addYears(max, 1));
  return filledDates.map((d) => {
    const value = dataMap[formatISO(d)];
    if (!value) return { ...defaultObject, [xValue]: d };
    return value;
  });
}
