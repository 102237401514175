import { scaleLinear } from "d3";
import { piecewise, interpolateRgb } from "d3";
import { AnyScale } from "../scales";
export const continuous = ["#FF5D2D", "#E3066A", "#BA01FF", "#1B96FF", "#04E1CB"];

export const createContinuousScale = (domain: [number, number]) => {
  const interpolate = piecewise(interpolateRgb.gamma(2.2), continuous);
  return scaleLinear()
    .domain(domain)
    .range([0, 1])
    .interpolate(() => interpolate);
};

export type ContinuousColorScale = ReturnType<typeof createContinuousScale>;
export const isContinuousScale = (x: AnyScale): x is ContinuousColorScale =>
  x.domain().length === 2 && typeof x.domain()[0] === "number" && typeof x.domain()[1] === "number";
