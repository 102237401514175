import { Bar } from "../bar-chart/bar";
import { BandScale, DiscreteColorScale, LinearScale } from "../scales";
import { Data } from "../shared/types";

interface Props {
  xScale: BandScale;
  yScale: LinearScale;
  keys: string[];
  xKey: string;
  colorScale: DiscreteColorScale;
  data: Data[];
}

export const BarSimple = ({ xScale, yScale, xKey, keys, colorScale, data }: Props) => {
  return (
    <>
      {data.map((d) => {
        const key = keys[0]!;
        const barWidth = xScale.bandwidth();
        const value = d[key] as number;
        const x = xScale(d[xKey] as string)!;
        const y = Math.min(yScale(value), yScale(0));
        const height = Math.abs(yScale(value) - yScale(0));
        const fill = colorScale(key);
        const active = true;
        return <Bar key={`bar-${d[xKey]}`} x={x} y={y} width={barWidth} height={height} fill={fill} active={active} />;
      })}
    </>
  );
};
