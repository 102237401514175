export const VisLoading = () => {
  return (
    <div className="h-full w-full ">
      <div className="h-full animate-pulse rounded p-4 dark:border-gray-700 md:p-6">
        <div className="mb-2.5 h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        <div className="mb-10 h-2 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        <div className="flex h-full w-full items-baseline space-x-6">
          <div className="h-1/4 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/2 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-3/4 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/3 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-2/3 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/4 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/2 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/3 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-2/3 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/4 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/2 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-3/4 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/3 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-2/3 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-1/4 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
