import { formatNumber } from "./util";
import clsx from "clsx";
import { format } from "d3";

interface BigNumberProps {
  width: number;
  height: number;
  caption?: string;
  prefix?: string;
  suffix?: string;
  data: number;
  decimals?: number;
  autoFormat?: boolean;
  d3Format?: string;
}

type size = "xxl" | "xl" | "lg" | "md" | "sm";

const getSize = (width: number, height: number, numberLength: number): size => {
  const ratio = Math.round(width / numberLength);

  if (width >= 600 && height >= 180 && ratio > 70) {
    return "xxl";
  } else if (width >= 400 && height >= 180 && ratio > 75) {
    return "xxl";
  } else if (width >= 260 && height >= 180 && ratio > 60) {
    return "xl";
  } else if (width >= 250) {
    return "lg";
  } else if (width >= 150) {
    return "md";
  } else {
    return "sm";
  }
};

export function BigNumber({
  width,
  height,
  data,
  decimals,
  autoFormat,
  d3Format,
  caption = "",
  prefix = "",
  suffix = "",
}: BigNumberProps) {
  let value = "";

  // render auto formatted value if autoFormat is true, otherwise render d3 formatted value or non-formatted value
  if (autoFormat) {
    value = formatNumber(data, decimals);
  } else {
    if (d3Format) {
      try {
        value = format(d3Format)(data);
      } catch (e) {
        // catch and render non-formatted value to avoid an error state as the user types in the d3 format
        value = data.toString();
      }
    } else {
      value = data.toString();
    }
  }

  const formattedValue = prefix + value + suffix;
  const size = getSize(width, height, formattedValue.length);
  // const ratio = Math.round(width / formattedValue.length);

  return (
    <div className="flex flex-col items-center p-4 justify-center h-full dark:text-gray-30">
      <div
        className={clsx("w-full text-center tracking-tighter", {
          "text-9xl": size === "xxl",
          "text-8xl": size === "xl",
          "text-6xl": size === "lg",
          "text-4xl": size === "md",
          "text-2xl": size === "sm",
          // uncomment for easier debugging
          // "text-red-500": size === "xxl",
          // "text-orange-500": size === "xl",
          // "text-yellow-500": size === "lg",
          // "text-green-500": size === "md",
          // "text-blue-500": size === "sm",
        })}
      >
        {formattedValue}
      </div>
      {caption && (
        <div className="py-4 text-base text-[#4D4D4D] dark:text-gray-40">
          {caption}
          {/* height: {height}, width: {width}, ratio: {ratio}, length: {formattedValue.length} */}
        </div>
      )}
    </div>
  );
}
