import { localPoint } from "@visx/event";
import { Pie } from "@visx/shape";
import { Text } from "@visx/text";
import { Provider, createStore, useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { ChartWrapper } from "../internal/components/chart";
import { useConstant } from "../internal/hooks";
import { useRunOnce } from "../internal/hooks/useRunOnce";
import { ChartProps } from "../shared/types";
import { Tooltip } from "../tooltip/tooltip";
import state, { chartMachineAtom, configAtom } from "./state";
import type { HoleSize, LabelOption, PieData } from "./types";

export interface PieChartProps extends ChartProps {
  labelKey: string;
  valueKey: string;
  holeSize?: HoleSize;
  labelOption?: LabelOption;
  maxSlices?: number;
  groupAdditionalSlices?: boolean;
}

const PieChart = (props: PieChartProps) => {
  const { displayMode = "normal", margin } = props;
  const [machine, send] = useAtom(chartMachineAtom);
  const labelKey = state.useLabelKey();
  const labelOption = state.useLabelOption();
  const valueKey = state.useValueKey();
  const radius = state.useRadius();
  const donutThickness = state.useDonutThickness();
  const data = state.useData();
  const colorScale = state.useColorScale();
  const innerWidth = state.useInnerWidth();
  const innerHeight = state.useInnerHeight();
  const centerX = innerWidth / 2;
  const centerY = innerHeight / 2;

  const getLabelDisplay = useCallback(
    (d: PieData) => {
      const map = {
        label: d[labelKey],
        labelAndValue: `${d[labelKey]} (${(d[valueKey] as number).toFixed(2)})`,
        percent: `${(d.percent * 100).toFixed(2)}%`,
        valueAndPercent: `${d[valueKey]} (${(d.percent * 100).toFixed(2)}%)`,
        labelAndPercent: `${d[labelKey]} (${(d.percent * 100).toFixed(2)}%)`,
      };
      return map[labelOption];
    },
    [labelOption, labelKey, valueKey],
  );

  return (
    <div>
      <ChartWrapper
        {...props}
        colorScale={colorScale}
        filteredKeys={machine.context.legend.filterKeys}
        onLegendClick={(key) => send({ type: "LEGEND.CLICK", key })}
        onLegendHover={(key) => send({ type: "LEGEND.HOVER", key })}
        onLegendLeave={() => send("LEGEND.LEAVE")}
        top={centerY + margin.top}
        left={centerX + margin.left}
      >
        <Pie data={data} pieValue={(d) => d.percent} outerRadius={radius} innerRadius={radius - donutThickness}>
          {(pie) => {
            return pie.arcs.map((arc, index) => {
              const [centroidX, centroidY] = pie.path.centroid(arc);
              const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.3;
              const width = donutThickness - 10;
              const hovered = machine.context.legend.hoveredKey || machine.context.tooltip.activeData;
              const active =
                !hovered ||
                machine.context.legend.hoveredKey === arc.data[labelKey] ||
                machine.context.tooltip.activeData?.[labelKey] === arc.data[labelKey];
              const opacity = active ? "1" : "0.3";
              return (
                <g key={`pie-arc-${index}`}>
                  <path
                    d={pie.path(arc) || ""}
                    fill={colorScale(arc.data[labelKey] as string)}
                    opacity={opacity}
                    id={`curve-${index}`}
                    onMouseMove={(e) => {
                      const { x, y } = localPoint(e) || { x: 0, y: 0 };
                      send({
                        type: "TOOLTIP.OPEN",
                        data: arc.data,
                        clientX: x,
                        clientY: y,
                      });
                    }}
                    onMouseLeave={() => send("TOOLTIP.CLOSE")}
                  />
                  {hasSpaceForLabel && displayMode === "normal" && (
                    <Text x={centroidX} y={centroidY} fontSize={12} textAnchor="middle" width={width} fill="white">
                      {getLabelDisplay(arc.data)}
                    </Text>
                  )}
                </g>
              );
            });
          }}
        </Pie>
      </ChartWrapper>
      {machine.matches("tooltip.opened") && (
        <Tooltip
          data={machine.context.tooltip.activeData!}
          xValue={labelKey}
          yValues={[valueKey]}
          colorScale={colorScale}
          colorValue={labelKey}
          left={machine.context.tooltip.clientX ?? 0}
          top={machine.context.tooltip.clientY ?? 0}
          hideZeros={props.tooltip?.hideZeros ?? false}
        />
      )}
    </div>
  );
};

const PieChartProvider = (props: PieChartProps) => {
  const store = useConstant(() => createStore());
  useRunOnce(() => {
    store.set(configAtom, props);
  });
  useEffect(() => {
    store.set(configAtom, props);
  }, [props, store]);
  return (
    <Provider store={store}>
      <PieChart {...props} />
    </Provider>
  );
};

export default PieChartProvider;
