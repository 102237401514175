import { extent } from "d3";
import { BandScaleProps, createBandScale } from "./band";
import { createLinearScale } from "./linear";
import { createLogScale } from "./log";
import { createTimeScale } from "./time";
import { ScaleType } from "./types";

type Domain = Date[] | string[] | number[];

export function createScale(domain: Domain, range: [number, number], scaleType: ScaleType, config?: BandScaleProps) {
  if (scaleType !== "auto") return createScaleFromScaleType(domain, range, scaleType, config);
  if (domain[0] instanceof Date) {
    return createTimeScale(extent(domain as Date[]) as [Date, Date], range);
  }
  if (typeof domain[0] === "string") {
    return createBandScale(domain as string[], range, config);
  }
  return createLinearScale(extent(domain as number[]) as [number, number], range);
}

function createScaleFromScaleType(
  domain: Domain,
  range: [number, number],
  scaleType: ScaleType,
  config?: BandScaleProps,
) {
  switch (scaleType) {
    case "linear":
      return createLinearScale(extent(domain as number[]) as [number, number], range);
    case "log":
      const [, max] = extent(domain as number[]) as [number, number];
      return createLogScale([1, max], range);
    case "band":
      return createBandScale(domain as string[], range, config);
    case "time":
      return createTimeScale(extent(domain as Date[]) as [Date, Date], range);
    default:
      return createLinearScale(extent(domain as number[]) as [number, number], range);
  }
}
