import { VisualizationUpdate } from "@fscrypto/domain/visualization";
import { Actor } from "./actor";
import { useActorFromSystem } from "~/state/system";

export const useVisualization = (visId: string) => {
  const [state, ref] = useActorFromSystem<Actor>(`visualization-${visId}-root`);

  if (!state || !ref)
    return {
      isReady: false,
    } as const;
  return {
    visualization: state.context.visualization,
    isReady: true,
    update: (visualization: VisualizationUpdate) =>
      ref.send({ type: "VISUALIZATION.UPDATE", payload: { visualization } }),
    updateTitle: (title: string) =>
      ref.send({ type: "VISUALIZATION.UPDATE_TITLE", payload: { visualization: { title } } }),
    deleteVis: () => ref.send({ type: "VISUALIZATION.DELETE" }),
    isSaving: state.matches("saving"),
    isDeleting: state.matches("deleting"),
  };
};
