import { TooltipWithBounds } from "@visx/tooltip";
import { format } from "date-fns";
import { useMemo } from "react";
import type { Data } from "../shared/types";
import { number } from "../internal/formatters";
import { isNumber } from "lodash-es";
import { CategoricalColorScale, ContinuousColorScale } from "../colors";
import { AnyTickFormatter } from "../axis/axis";
import { ClockIcon } from "lucide-react";

interface TooltipProps {
  data: Data;
  top: number;
  left: number;
  dark?: boolean;
  xValue: string;
  xFormatter?: AnyTickFormatter;
  yValues: string[];
  colorScale?: CategoricalColorScale | ContinuousColorScale;
  colorValue?: string;
  hideZeros?: boolean;
}
export const Tooltip = ({
  data,
  colorScale,
  top,
  left,
  xValue,
  yValues,
  colorValue,
  xFormatter,
  hideZeros,
}: TooltipProps) => {
  const x = data?.[xValue] || "";
  const isDate = x instanceof Date;
  const xDisplay = xFormatter?.(x as string & number, 0, []) || (x as string);
  const filteredYValues = useMemo(
    () =>
      yValues.filter((y) => {
        if (hideZeros) {
          return !!data[y];
        }
        return data[y] !== undefined && data[y] !== null;
      }),
    [data, yValues, hideZeros],
  );
  if (!data) return null;
  return (
    <TooltipWithBounds
      className="bg-background absolute rounded-md border p-2 text-xs"
      unstyled
      top={top}
      left={left}
      offsetLeft={30}
    >
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center font-semibold">
          <div className="mr-1">
            {isDate && <ClockIcon className="size-4" />}
            {!isDate && <h3>{xValue}: </h3>}
          </div>
          <span className="max-w-[100px] truncate">{xDisplay}</span>
        </div>
        {filteredYValues.map((yValue) => {
          const cValue = colorValue ? (data[colorValue] as string) : yValue;
          const color = colorScale ? colorScale(cValue as string & number) : "black";
          const yDisplay = getYDisplay(data[yValue] as string | number | Date);
          return (
            <div className="flex items-center font-semibold" key={yValue}>
              <div className="mr-1">
                <div className="h-2 w-2 rounded-full" style={{ backgroundColor: color }} />
              </div>
              <h3 className="mr-1 max-w-[100px] truncate">{yValue}</h3>
              <span style={{ color }}>{yDisplay}</span>
            </div>
          );
        })}
      </div>
    </TooltipWithBounds>
  );
};

function getYDisplay(value: string | number | Date) {
  if (value instanceof Date) {
    return format(value, "yyyy-MM-dd");
  }
  if (isNumber(value)) {
    return number(value as number);
  }
  return value;
}
export type MouseMoveHandler = (
  event: React.MouseEvent<SVGElement, MouseEvent>,
  data?: Data,
  yValues?: string[],
) => void;
