import type { visualization } from "@fscrypto/domain";
import {
  LineChartIcon,
  BarChartFilledIcon,
  ScatterChartIcon,
  PieChartFilledIcon,
  ScorecardIcon,
  AreaChartIcon,
} from "@fscrypto/ui";

export type ChartType = Exclude<visualization.Chart["type"], "bar_line">;
interface NewVisualizationProps {
  onChoose: (vis: ChartType) => void;
  creating: boolean;
}
export const NewVisualization = ({ onChoose, creating }: NewVisualizationProps) => {
  return (
    <div className="flex h-full w-full items-center justify-center gap-x-4">
      <ChartCard chartType="bar" onClick={() => onChoose("bar")} disabled={creating} />
      <ChartCard chartType="line" onClick={() => onChoose("line")} disabled={creating} />
      <ChartCard chartType="area" onClick={() => onChoose("area")} disabled={creating} />
      <ChartCard chartType="donut" onClick={() => onChoose("donut")} disabled={creating} />
      <ChartCard chartType="scatter" onClick={() => onChoose("scatter")} disabled={creating} />
      <ChartCard chartType="big_number" onClick={() => onChoose("big_number")} disabled={creating} />
    </div>
  );
};

interface ChartCardProps {
  chartType: ChartType;
  onClick?: () => void;
  disabled?: boolean;
}

export const metadataMap: (
  width?: number,
  height?: number,
) => Record<ChartType, { icon: React.ReactNode; label: string; hints: string[] }> = (width = 40, height = 40) => ({
  area: {
    icon: <AreaChartIcon width={width} height={height} />,
    label: "Area Chart",
    hints: ["a column for X Axis", "a column for Y Axis"],
  },
  bar: {
    icon: <BarChartFilledIcon width={width} height={height} />,
    label: "Bar Chart",
    hints: ["a column for X Axis", "a column for Y Axis (Bar)"],
  },
  line: {
    icon: <LineChartIcon width={width} height={height} />,
    label: "Line Chart",
    hints: ["a column for X Axis", "a column for Y Axis"],
  },
  donut: {
    icon: <PieChartFilledIcon width={width} height={height} />,
    label: "Donut Chart",
    hints: ["a column for Slice (which slices are in the chart)", "a column for Angle (how big those slices are)"],
  },
  scatter: {
    icon: <ScatterChartIcon width={width} height={height} />,
    label: "Scatter Chart",
    hints: ["a column for X Axis", "a column for Y Axis"],
  },
  big_number: {
    icon: <ScorecardIcon width={width} height={height} />,
    label: "Single Number",
    hints: ["a column (where the number will come from)", "a row number from that column to display"],
  },
});

const ChartCard = ({ chartType, onClick, disabled = false }: ChartCardProps) => {
  return (
    <button
      onClick={onClick}
      className="border-gray-15 dark:border-gray-80 flex h-[112px] w-[112px] flex-col items-center justify-center gap-y-2 rounded-md border py-4 shadow-sm disabled:opacity-50 dark:bg-gray-100"
      disabled={disabled}
      data-testid={`visualization-create-${chartType}`}
    >
      <div>{metadataMap()[chartType]?.icon}</div>
      <span className="dark:text-gray-30 text-xs">{metadataMap()[chartType]?.label}</span>
    </button>
  );
};
