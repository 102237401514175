import { uniq } from "lodash-es";
import { Data } from "../shared/types";
import { Domain } from "./types";

export function getStringDomain(data: Data[], keys: string[]): Domain<string> {
  const values = data.map((d) => keys?.map((k) => d[k])).flat() as string[];
  return {
    values: uniq(values),
    min: undefined,
    max: undefined,
  };
}
