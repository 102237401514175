import { extent, group, sum } from "d3";
import { atom, useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { identity, sumBy } from "lodash-es";
import { xyChartPropsToAtoms } from "../internal/atoms/chartPropsToAtoms";
import type { TickFormatter } from "@visx/axis";
import { categorical } from "../internal/colors";
import { aggregate, fillMissingDates, isTimeSeries } from "../internal/data";
import { BandScale, createOrdinalScale, createScale, DiscreteColorScale, LinearScale, LogScale } from "../scales";
import { Data } from "../shared/types";
import { getXTickFormat, getYTickFormat } from "../utils/tickFormat";
import { BarLineProps } from "./bar-line";

interface BarLineState {
  useData: () => Data[];
  useYLeftBarKeys: () => string[];
  useYLeftLineKeys: () => string[];
  useYRightBarKeys: () => string[];
  useYRightLineKeys: () => string[];
  useXScale: () => BandScale;
  useXGroupedScale: () => BandScale;
  useYLeftScale: () => LinearScale | LogScale;
  useYRightScale: () => LinearScale | LogScale | null;
  useColorScale: () => DiscreteColorScale;
  useYTickFormat: () => TickFormatter<number>;
  useXTickFormat: () => TickFormatter<string> | TickFormatter<Date>;
}

export const barLineChartState: BarLineState = {
  useData: () => useAtomValue(dataAtom),
  useYLeftBarKeys: () => useAtomValue(leftBarKeysAtom),
  useYRightBarKeys: () => useAtomValue(rightBarKeysAtom),
  useYLeftLineKeys: () => useAtomValue(leftLineKeysAtom),
  useYRightLineKeys: () => useAtomValue(rightLineKeysAtom),
  useXScale: () => useAtomValue(xScaleAtom),
  useXGroupedScale: () => useAtomValue(xGroupedScaleAtom),
  useYLeftScale: () => useAtomValue(yScaleLeftAtom),
  useYRightScale: () => useAtomValue(yScaleRightAtom),
  useColorScale: () => useAtomValue(colorScaleAtom),
  useYTickFormat: () => useAtomValue(yTickFormatterAtom),
  useXTickFormat: () => useAtomValue(xTickFormatterAtom),
};

const {
  configAtom,
  rawDataAtom,
  groupingKeyAtom,
  innerWidthAtom,
  innerHeightAtom,
  xKeyAtom,
  yKeysAtom,
  yAxisRightScaleTypeAtom,
  yAxisScaleTypeAtom,
} = xyChartPropsToAtoms<BarLineProps>();

export { configAtom };

const seriesConfigAtom = selectAtom(configAtom, (v) => v.seriesConfig);

const dataAtom = atom((get) => {
  const rawData = get(rawDataAtom);
  const xKey = get(xKeyAtom);
  const yKeys = get(yKeysAtom);
  const groupingKey = get(groupingKeyAtom);
  const data = aggregate({ data: rawData, xKey, yKeys, groupingKey });
  if (isTimeSeries(data, xKey)) {
    return fillMissingDates(data, xKey);
  }
  return data;
});

const filteredSeriesConfigAtom = atom((get) => {
  const seriesConfig = get(seriesConfigAtom);
  const data = get(dataAtom);
  const dataKeys = Object.keys(data[0]!);
  return seriesConfig.filter((s) => dataKeys.includes(s.key));
});

// Default for everything that is not specified in the seriesConfig
const leftBarKeysAtom = atom((get) => {
  const seriesConfig = get(filteredSeriesConfigAtom);
  if (seriesConfig.length > 0) {
    const seriesKeys = seriesConfig
      .filter((s) => (s.type === "bar" && s.axis !== "right") || !s.type)
      .map((s) => s.key);
    if (seriesKeys.length > 0) return seriesKeys;
  }
  const data = get(dataAtom);
  const xKey = get(xKeyAtom);
  const seriesKeys = seriesConfig.map((s) => s.key);
  return Object.keys(data[0]!).filter((k) => k !== xKey && !seriesKeys.includes(k));
});

const rightBarKeysAtom = atom((get) => {
  const seriesConfig = get(filteredSeriesConfigAtom);
  if (seriesConfig.length === 0) return [];
  const keys = seriesConfig.filter((s) => s.type === "bar" && s.axis === "right").map((s) => s.key);
  return keys;
});

const leftLineKeysAtom = atom((get) => {
  const seriesConfig = get(filteredSeriesConfigAtom);
  if (seriesConfig.length === 0) return [];
  const keys = seriesConfig.filter((s) => s.type === "line" && s.axis !== "right").map((s) => s.key);
  return keys;
});

const rightLineKeysAtom = atom((get) => {
  const seriesConfig = get(filteredSeriesConfigAtom);
  if (seriesConfig.length === 0) return [];
  const keys = seriesConfig.filter((s) => s.type === "line" && s.axis === "right").map((s) => s.key);
  return keys;
});

const xScaleAtom = atom((get) => {
  const data = get(dataAtom);
  const xKey = get(xKeyAtom);
  const width = get(innerWidthAtom);
  const domain = data.map((d) => d[xKey]) as Date[] | string[];
  return createScale(domain, [0, width], "band") as BandScale;
});

const xGroupedScaleAtom = atom((get) => {
  const barKeys = get(leftBarKeysAtom);
  const xScale = get(xScaleAtom);
  return createScale(barKeys, [0, xScale.bandwidth()], "band", { padding: 0.1 }) as BandScale;
});

const yScaleLeftAtom = atom((get) => {
  const barKeys = get(leftBarKeysAtom);
  const lineKeys = get(leftLineKeysAtom);
  const data = get(dataAtom);
  const height = get(innerHeightAtom);
  const barDomain = getYDomain(data, barKeys, get(xKeyAtom), get(configAtom).displayType);
  const lineDomain = getYDomain(data, lineKeys, get(xKeyAtom), "grouped");
  const domain = [Math.min(barDomain[0], lineDomain[0]), Math.max(barDomain[1] ?? 0, lineDomain[1] ?? 0)] as [
    number,
    number,
  ];
  return createScale(domain, [height, 0], get(yAxisScaleTypeAtom) ?? "linear") as LinearScale;
});

const yScaleRightAtom = atom((get) => {
  const barKeys = get(rightBarKeysAtom);
  const lineKeys = get(rightLineKeysAtom);
  if (barKeys.length === 0 && lineKeys.length === 0) return null;
  const data = get(dataAtom);
  const height = get(innerHeightAtom);
  const barDomain = getYDomain(data, barKeys, get(xKeyAtom), "stacked");
  const lineDomain = getYDomain(data, lineKeys, get(xKeyAtom), "grouped");
  const domain = [Math.min(barDomain[0], lineDomain[0]), Math.max(barDomain[1] ?? 0, lineDomain[1] ?? 0)] as [
    number,
    number,
  ];
  return createScale(domain, [height, 0], get(yAxisRightScaleTypeAtom) ?? "linear") as LinearScale | LogScale;
});

const colorScaleAtom = atom((get) => {
  const allKeys = [
    ...get(leftBarKeysAtom),
    ...get(rightBarKeysAtom),
    ...get(leftLineKeysAtom),
    ...get(rightLineKeysAtom),
  ];
  return createOrdinalScale(allKeys, categorical);
});

const xTickFormatterAtom = atom((get) => {
  const xKey = get(xKeyAtom);
  const data = get(dataAtom);
  if (!data.length) return identity;
  return getXTickFormat(data, xKey);
});

const yTickFormatterAtom = atom((get) => {
  const data = get(dataAtom);
  const keys = [...get(leftBarKeysAtom), ...get(leftLineKeysAtom)];
  const domain = getYDomain(data, keys, get(xKeyAtom), get(configAtom).displayType);
  return getYTickFormat(domain);
});

export function getYDomain(
  data: Data[],
  yValues: string[],
  xValue: string,
  displayType: "stacked" | "grouped" = "stacked",
): [number, number] {
  let [min, max] = [0, 0];
  if (displayType === "grouped") {
    const allValues = data.map((d) => yValues.map((y) => d[y])).flat() as number[];
    [min, max] = extent(allValues) as [number, number];
  } else {
    const groupedData = group(data, (d) => d[xValue]);
    const allPositiveValues = Array.from(groupedData, ([, values]) => {
      const sumValues = sumBy(values, (d) => sum(yValues.map((y) => d[y] as number).filter((v) => v >= 0)));
      return sumValues;
    });
    const allNegativeValues = Array.from(groupedData, ([, values]) => {
      const sumValues = sumBy(values, (d) => sum(yValues.map((y) => d[y] as number).filter((v) => v < 0)));
      return sumValues;
    });
    [min, max] = [Math.min(...allNegativeValues), Math.max(...allPositiveValues) as number];
  }
  return [min < 0 ? min : 0, max];
}
