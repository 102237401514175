import { scaleLinear } from "@visx/scale";

export function createLinearScale([min, max]: [number, number], [rangeMin = 0, rangeMax]: [number, number]) {
  return scaleLinear<number>({
    domain: [min, max],
    range: [rangeMin, rangeMax],
    nice: true,
    round: true,
  });
}
