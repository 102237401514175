import type { visualization } from "@fscrypto/domain";
import { BarLineChartLegacy, ParentSize } from "@fscrypto/viz";
import { getMargin } from "./shared";
import type { ChartProps } from "./types";

interface BarLineChartProps extends ChartProps<visualization.BarLineLegacyChart> {}

export const BarLineChartVisualization = ({ chart, legend, data, displayMode, margin }: BarLineChartProps) => {
  const { xAxisValue, yAxisValues } = chart;
  if (data.length === 0 || !xAxisValue || yAxisValues.length === 0) return null;
  return (
    <div className="h-full w-full">
      <ParentSize>
        {({ width, height }) => (
          <BarLineChartLegacy
            data={data}
            xKey={xAxisValue}
            yKeys={chart.yAxisValues}
            height={height}
            width={width}
            groupingKey={chart.groupByValue}
            displayType={chart.displayType}
            xAxisLabel={chart.xAxisLabel}
            yAxisLabel={chart.yAxisLabel}
            yAxisScale={chart.yAxisScale as "linear" | "log"}
            yAxisRightScale={chart.yAxisRightScale as "linear" | "log" | undefined}
            showLegend={legend.show}
            legendPosition="top"
            margin={margin ?? getMargin(width)}
            seriesConfig={chart.seriesConfigSchema}
            displayMode={displayMode}
            xAxisSortDirection={"none"}
          />
        )}
      </ParentSize>
    </div>
  );
};
